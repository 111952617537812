import { Dispatch } from 'redux';

import { IReservation } from '../entities/IReservation';
import { IState } from '../reducers';

export const createReservation = (body: IReservation): Promise<IReservation> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`http://d2kqdrigyj90hu.cloudfront.net/api/reservation`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token as string
        },
        body: JSON.stringify(body)
    }).then((res: any) => res.json().content).then((response: IReservation) => {
        return response;
    });
}) as any;

export const getReservation = (reservationId: string): Promise<IReservation> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`http://d2kqdrigyj90hu.cloudfront.net/api/reservation/${reservationId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token as string
        }
    }).then((res: any) => res.json().content).then((response: IReservation) => {
        return response;
    });
}) as any;

export const editReservation = (reservation: string, body: IReservation): Promise<IReservation> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`http://d2kqdrigyj90hu.cloudfront.net/api/reservation/${reservation}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token as string
        },
        body: JSON.stringify(body)
    }).then((res: any) => res.json().content).then((response: IReservation) => {
        return response;
    });
}) as any;

export const getAllReservation = (): Promise<IReservation[]> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`http://d2kqdrigyj90hu.cloudfront.net/api/reservation`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token as string
        }
    }).then((res: any) => res.json().content).then((response: IReservation[]) => {
        return response;
    });
}) as any;