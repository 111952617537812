import { FC, useState, useEffect } from 'react';
import styled from 'styled-components';

import { Button } from './Buttons/Button';
import { colorStack } from '../../style/colorStack';
import { getCookie, setCookie } from '../../tools/cookies';

const Wrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    background: ${colorStack.white};
    box-shadow: 0px 8px 32px rgba(0,0,0, .2);
    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1024px;
        width: 100%;
        margin: 0 auto;
    }
`;

export const CookiesBanner: FC = () => {
    const [showBanner, setShowBanner] = useState<boolean>(false);

    useEffect(() => {
        const isCookie = getCookie('PlayAirCookie');
        if (!isCookie) {
            setShowBanner(true);
        }
    }, []);

    const closeBanner = () => {
        setCookie('PlayAirCookie', true, 365);
        setShowBanner(false);
    };

    return (
        <>
            {showBanner ? (
                <Wrapper>
                    <div>
                        <p>Ta strona używa ciasteczek (cookies), dzięki którym nasza witryna może działać lepiej.</p>
                        <Button onClick={closeBanner}>Rozumiem</Button>
                    </div>
                </Wrapper>
            // tslint:disable-next-line:no-null-keyword
            ) : null}
        </>
    );
};