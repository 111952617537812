import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { media } from '../../style/breakpoints';
import { colorStack } from '../../style/colorStack';
import { TopMenu } from './TopMenu';
import { getCookie } from '../../tools/cookies';
import { getCurrentUser } from '../../actions/userProfileActions';
import { setToken } from '../../actions/securityActions';
import { Footer } from './Footer';
import { CookiesBanner } from './CookiesBanner';

const Wrapper = styled.div``;

const Content = styled.div`
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    background: ${colorStack.white};
    min-height: calc((100vh - 64px - 88px) - 1rem);
    margin-top: .5rem;
    border-radius: 8px;
    padding: .5rem;
    ${media.desktop`
        padding: 2rem;
    `};
`;

interface IProps {
    children: JSX.Element;
}

export const Layout: FC<IProps> = props => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const tokenFromCookie = getCookie('PlayAirAuth');
        if (tokenFromCookie.length) {
            dispatch<any>(setToken(tokenFromCookie));
            dispatch<any>(getCurrentUser(tokenFromCookie)).then(() => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, []);

    return (
        <Wrapper>
            {!loading ?
                <>
                    <TopMenu />
                    <Content>
                        {props.children}
                    </Content>
                    <Footer />
                    <CookiesBanner />
                </>
                : <>Ładowanie aplikacji</>}
        </Wrapper>
    );
};