import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';

import { createSingleEvent, getSingleEvent, updateSingleEvent } from '../../actions/eventActions';
import { IEvent } from '../../entities/IEvent';
import { emptyEvent } from '../../tools/emptyObjects/emptyEvent';
import { Checkbox } from '../common/Inputs/Checkbox';
import { TextComponent } from '../common/Inputs/TextComponent';
import { Avatar } from '../common/Avatar';
import { IUserProfile } from '../../entities/IUserProfile';
import { Row } from '../common/Row';
import { RichTextEditor } from '../common/RichText/RichTextEditor';
import { Dropzone } from '../common/Dropzone/Dropzone';
import { MultiObjectArea } from '../common/MultiObjectArea/MultiObjectArea';
import { ISingleTicket } from '../../entities/IGlobal';
import { SinglerTicket } from '../common/SingleTicket';
import { PageTitle } from '../common/PageTitle';
import { DatePicker } from '../common/Inputs/DatePicker';
import { AddressComponent } from '../common/Inputs/AddressComponent';
import { colorStack } from '../../style/colorStack';
import { ActionMenu } from '../common/ActionMenu';
import { ImageCropper } from '../common/ImageCropper';

const Wrapper = styled.div`
    position: relative;
`;

const Limits = styled.div`
    border-left: 1px solid ${colorStack.darkGrey2};
    padding-left: 1rem;
    &:first-child {
        border: none;
        padding: 0;
    }
    span {
        display: block;
        text-align: center;
        margin-bottom: 1rem;
    }
`;

export const EditEvent: FC = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [eventObj, setEventObj] = useState<IEvent | undefined>(undefined);
    const [ticketName, setTicketName] = useState<string>('');
    const [ticketPrice, setTicketPrice] = useState<string>('');
    const [ticketDescription, setTicketDescription] = useState<string>('');

    useEffect(() => {
        if (id) {
            dispatch<any>(getSingleEvent(id)).then((res: IEvent) => setEventObj(res));
        } else {
            setEventObj(emptyEvent);
        }
    }, [id]);

    const changeData = (field: string, data: any) => {
        //
    };

    const cancelHandler = () => {
        history('/events');
    };

    const saveHandler = () => {
        if (id && eventObj) {
            dispatch<any>(updateSingleEvent(id, eventObj)).then((res: IEvent) => setEventObj(res));
        } else if (eventObj) {
            dispatch<any>(createSingleEvent(eventObj)).then((res: IEvent) => setEventObj(res));
        }
    };

    const saveTicketToObj = () => {
        const newTicket: ISingleTicket = {
            id: '',
            name: ticketName,
            price: ticketPrice,
            description: ticketDescription
        };
        setEventObj(state => state ? {
            ...state,
            defaultData: {
                ...state.defaultData,
                ticketPrice: state.defaultData.ticketPrice ? [...state.defaultData.ticketPrice, newTicket] : [newTicket]
            }
        } : state);
        setTicketName('');
        setTicketPrice('');
        setTicketDescription('');
    };

    const changeNameTicket = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setTicketName(text);
    };

    const changePriceTicket = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setTicketPrice(text);
    };

    const changeDescriptionTicket = (content: string) => {
        setTicketDescription(content);
    };

    return (
        <Wrapper>
            <ActionMenu>
                <FontAwesomeIcon icon={faBan} onClick={cancelHandler} />
                <FontAwesomeIcon icon={faFloppyDisk} onClick={saveHandler} />
            </ActionMenu>
            {id ? (
                <PageTitle h1="Edytuj event" h2="Edytuj informacje o swoim evencie" />
            ) : (
                <PageTitle h1="Nowy event" h2="Stwórz nowy event" />
            )}
            <ImageCropper label="Obrazek" />
            <Row numberOfElements={3}>
                <TextComponent value={eventObj?.name} onChange={(e) => changeData('', e.target.value)} label="Nazwa" required />
                <Avatar<IUserProfile>
                    id={''}
                    name={`Imię i nazwisko`}
                    pictureUrl="http://fubar13th.pl/img/logo.png"
                    data={eventObj?.headMaster}
                    label="Organizator" required
                />
                <TextComponent value="" onChange={(e) => changeData('', e.target.value)} label="Arena" />
            </Row>
            <Row numberOfElements={3}>
                <AddressComponent address={eventObj?.address} onSelect={(e) => changeData('', e)} label="Adres" required />
                <DatePicker label="Start" onChange={(date) => { /** */ }} value={eventObj?.startDate} required />
                <DatePicker label="Koniec" onChange={(date) => { /** */ }} value={eventObj?.endDate} required />
            </Row>
            <RichTextEditor label="Opis" value={eventObj?.description || ''} onChange={() => { /** */ }} />
            <Row numberOfElements={3}>
                <TextComponent value="" onChange={(e) => changeData('fb', e.target.value)} label="Link do facebook" />
                <TextComponent value="" onChange={(e) => changeData('insta', e.target.value)} label="Link do instagram" />
                <TextComponent value="" onChange={(e) => changeData('tw', e.target.value)} label="Link do twitter" />
            </Row>
            <Row numberOfElements={2}>
                <TextComponent value="" onChange={(e) => changeData('yt', e.target.value)} label="Link do youtube" />
                <TextComponent value="" onChange={(e) => changeData('www', e.target.value)} label="Link do www" />
            </Row>
            <Dropzone label="Zdjęcia" />
            <TextComponent value="" onChange={(e) => changeData('', e.target.value)} label="Drużyny" />
            <Row numberOfElements={6}>
                <Limits>
                    <span>4-ro takty/sniperki</span>
                    <TextComponent value={eventObj?.replicasLimits?.sniper.power.fps} onChange={(e) => changeData('', e.target.value)} label="FPS" />
                    <TextComponent value={eventObj?.replicasLimits?.sniper.power.jouls} onChange={(e) => changeData('', e.target.value)} label="Joule" />
                    <Checkbox field="sniper" checked={eventObj?.replicasLimits?.sniper.single || false} onChange={(e) => changeData('', e.target.checked)} label="Tylko semi" />
                </Limits>
                <Limits>
                    <span>DMR</span>
                    <TextComponent value={eventObj?.replicasLimits?.dmr.power.fps} onChange={(e) => changeData('', e.target.value)} label="FPS" />
                    <TextComponent value={eventObj?.replicasLimits?.dmr.power.fps} onChange={(e) => changeData('', e.target.value)} label="Joule" />
                    <Checkbox field="ageLimit" checked={eventObj?.replicasLimits?.dmr.single || false} onChange={(e) => changeData('', e.target.checked)} label="Tylko semi" />
                </Limits>
                <Limits>
                    <span>Repliki wsparcia</span>
                    <TextComponent value={eventObj?.replicasLimits?.support.power.fps} onChange={(e) => changeData('', e.target.value)} label="FPS" />
                    <TextComponent value={eventObj?.replicasLimits?.support.power.fps} onChange={(e) => changeData('', e.target.value)} label="Joule" />
                    <Checkbox field="support" checked={eventObj?.replicasLimits?.sniper.single || false} onChange={(e) => changeData('', e.target.checked)} label="Tylko semi" />
                </Limits>
                <Limits>
                    <span>CQB</span>
                    <TextComponent value={eventObj?.replicasLimits?.cqb.power.fps} onChange={(e) => changeData('', e.target.value)} label="FPS" />
                    <TextComponent value={eventObj?.replicasLimits?.cqb.power.fps} onChange={(e) => changeData('', e.target.value)} label="Joule" />
                    <Checkbox field="cqb" checked={eventObj?.replicasLimits?.cqb.single || false} onChange={(e) => changeData('', e.target.checked)} label="Tylko semi" />
                </Limits>
                <Limits>
                    <span>Repliki boczne</span>
                    <TextComponent value={eventObj?.replicasLimits?.sideReplica.power.fps} onChange={(e) => changeData('', e.target.value)} label="FPS" />
                    <TextComponent value={eventObj?.replicasLimits?.sideReplica.power.fps} onChange={(e) => changeData('', e.target.value)} label="Joule" />
                    <Checkbox field="sideReplica" checked={eventObj?.replicasLimits?.sideReplica.single || false} onChange={(e) => changeData('', e.target.checked)} label="Tylko semi" />
                </Limits>
                <Limits>
                    <span>Granaty</span>
                    <TextComponent value={eventObj?.replicasLimits?.grenades.power.fps} onChange={(e) => changeData('', e.target.value)} label="FPS" />
                    <TextComponent value={eventObj?.replicasLimits?.grenades.power.fps} onChange={(e) => changeData('', e.target.value)} label="Joule" />
                    <Checkbox field="grenades" checked={eventObj?.replicasLimits?.grenades.single || false} onChange={(e) => changeData('', e.target.checked)} label="Tylko semi" />
                </Limits>
            </Row>
            <TextComponent value={eventObj?.defaultData?.playerLimit?.toString() || ''} onChange={(e) => changeData('', e.target.value)} label="Limit użytkowników" />
            <Row numberOfElements={5}>

                <Checkbox field="legal" checked={eventObj?.defaultData?.legal || false} onChange={(e) => changeData('', e.target.checked)} label="Czy legalna" />
                <Checkbox field="chrono" checked={eventObj?.defaultData?.chrono || false} onChange={(e) => changeData('', e.target.checked)} label="Chronowanie obowiązkowe" />
                <Checkbox field="parking" checked={eventObj?.defaultData?.parking || false} onChange={(e) => changeData('', e.target.checked)} label="Dostępny parking" />
                <Checkbox field="onlyBio" checked={eventObj?.defaultData?.onlyBioBalls || false} onChange={(e) => changeData('', e.target.checked)} label="Kulki tylko BIO" />
                <Checkbox field="ageLimit" checked={eventObj?.defaultData?.ageLimit || false} onChange={(e) => changeData('', e.target.checked)} label="Limit wieku" />
            </Row>
            <MultiObjectArea<ISingleTicket>
                value={eventObj?.defaultData?.ticketPrice || []}
                label="Bilety"
                saveHandler={saveTicketToObj}
                singleElem={SinglerTicket}
            >
                <Row numberOfElements={2}>
                    <TextComponent value={ticketName} onChange={changeNameTicket} label="Nazwa biltetu" />
                    <TextComponent value={ticketPrice} onChange={changePriceTicket} label="Cena" />
                </Row>
                <RichTextEditor label="Opis" value={ticketDescription} onChange={changeDescriptionTicket} />
            </MultiObjectArea>
        </Wrapper>
    );
};