import { FC } from 'react';
import styled from 'styled-components';

import { IReplica } from '../../../entities/IReplica';
import { media } from '../../../style/breakpoints';
import { colorStack } from '../../../style/colorStack';
import { Pill } from '../Pill';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    ${media.desktop`
        flex-direction: row;
    `};
`;

const Left = styled.div`
    width: 250px;
    img {
        width: 100%;
    }
`;

const Right = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    ${media.desktop`
        width: calc(100% - 250px - 2rem);
    `}
`;

const Name = styled.div`
    font-size: 1.5rem;
    color: ${colorStack.blue};
`;

const Meta = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Description = styled.div`
    font-size: 1rem;
    color: ${colorStack.darkGrey};
    margin: .5rem 0 1rem 0;
`;

interface IProps {
    data: IReplica;
}

export const SingleReplica: FC<IProps> = props => {
    return (
        <Wrapper>
            <Left>
                <img src={props.data.picture} alt="Replika" />
            </Left>
            <Right>
                <Name>{props.data.name}</Name>
                <Description>{props.data.description}</Description>
                <Meta>
                    {props.data.balls && <Pill label="Kulki" text={props.data.balls} />}
                    {props.data.company && <Pill label="Firma" text={props.data.company} />}
                    {props.data.model && <Pill label="Model" text={props.data.model} />}
                    {props.data.range && <Pill label="Zasięg" text={props.data.range} />}
                    {props.data.power?.fps && <Pill label="FPS" text={props.data.power?.fps} />}
                    {props.data.power?.jouls && <Pill label="Joul" text={props.data.power?.jouls} />}
                    {props.data.gearbox && <Pill label="Gearbox" text={props.data.gearbox} />}
                    {props.data.type && <Pill label="Typ" text={props.data.type} />}
                    {props.data.magazineCapacity && <Pill label="Pojemność magazynka" text={props.data.magazineCapacity} />}
                    {props.data.weight && <Pill label="Waga" text={props.data.weight} />}
                </Meta>
            </Right>
        </Wrapper>
    );
};