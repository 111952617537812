import { Link } from 'react-router-dom';
import { FC } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faLocationDot } from '@fortawesome/free-solid-svg-icons';

import { IArena } from '../../entities/IArena';
import { colorStack } from '../../style/colorStack';
import { MultilineTextWithEllipsis } from './MultilineTextWithEllipsis';
import { Pill } from './Pill';
import { media } from '../../style/breakpoints';
import { Button } from './Buttons/Button';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    border: 1px solid ${colorStack.darkGrey2};
    border-radius: 8px;
    flex-direction: column;
    padding: 1rem;
    ${media.desktop`
        flex-direction: row;
    `};
    a {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        ${media.desktop`
            flex-direction: row;
        `};
    }
`;

const Left = styled.div`
    img {
        width: 100%;
    }
    ${media.desktop`
        width: 200px;
        margin-right: 2rem;
        img {
            width: 200px;
        }
    `}
`;

const Right = styled.div`
    ${media.desktop`
        width: calc(100% - 200px - 2rem);
        display: flex;
        justify-content: space-between;
    `}
`;

const ArenaName = styled.div`
    font-size: 1.5rem;
    color: ${colorStack.blue};
    font-weight: 700;
`;

const Address = styled.div`
    font-size: 1.1rem;
    margin-top: .5rem;
    color: ${colorStack.darkGrey};
    svg {
        margin-right: 1rem;
    }
`;

const Description = styled.div`
    font-size: 1rem;
    margin-top: 1rem;
    color: ${colorStack.darkGrey};
`;

const Meta = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const MetaBox = styled.div`
    display: flex;
    padding: 1rem 0;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
`;

const Buttons = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
    justify-content: space-between;
    > div {
        margin-bottom: 1rem;
    }
`;

interface IProps {
    arena: IArena;
}

export const SingleArenaBox: FC<IProps> = props => {
    return (
        <Wrapper>
            <Link to={`/arena/${props.arena.id}`} >
                <Left>
                    <img src={props.arena.pictureUrl} alt="Logo areny" />
                    <Buttons>
                        <Button onClick={() => {/** */ }}>Dodaj do ulubionych</Button>
                    </Buttons>
                </Left>
                <Right>
                    <MetaBox>
                        <div>
                            <ArenaName>
                                {props.arena.name}
                            </ArenaName>
                            <Address>
                                <FontAwesomeIcon icon={faLocationDot} />{props.arena.address?.zipCode} {props.arena.address?.city}, {props.arena.address?.street} {props.arena.address?.number}
                            </Address>
                            <Address>
                                <FontAwesomeIcon icon={faPhone} />{props.arena.address?.phone}
                            </Address>
                            <Description>
                                <MultilineTextWithEllipsis numberOfLines={3}>
                                    {props.arena.shortDescription}
                                </MultilineTextWithEllipsis>
                            </Description>
                        </div>
                        <Meta>
                            {props.arena.defaultData?.playerLimit && <Pill text={props.arena.defaultData?.playerLimit.toString()} label="Limit graczy" />}
                            {props.arena.defaultData?.legal && <Pill label="Legalna" />}
                            {props.arena.defaultData?.chrono && <Pill label="Chrono" />}
                            {props.arena.defaultData?.parking && <Pill label="Parking" />}
                            {props.arena.defaultData?.onlyBioBalls && <Pill label="Kulki BIO" />}
                        </Meta>
                    </MetaBox>
                </Right>
            </Link>
        </Wrapper>
    );
};