import { FC, useEffect, useState } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { registerUser } from '../../actions/securityActions';
import { ISecurityData } from '../../entities/IGlobal';
import { isValidEmail } from '../../tools/emailChecker';
import { Button } from '../common/Buttons/Button';
import { TextComponent } from '../common/Inputs/TextComponent';
import { getCurrentUser } from '../../actions/userProfileActions';
import { setCookie } from '../../tools/cookies';
import { PageTitle } from '../common/PageTitle';
import { Checkbox } from '../common/Inputs/Checkbox';

const Wrapper = styled.div``;

export const Register: FC = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const [loginValue, setLoginValue] = useState<string>('');
    const [emailValue, setEmailValue] = useState<string>('');
    const [passValue, setPassValue] = useState<string>('');
    const [pass2Value, setPass2Value] = useState<string>('');
    const [check, setCheck] = useState<boolean>(false);
    const [validation, setValidation] = useState<boolean>(true);

    useEffect(() => {
        if (loginValue && isValidEmail(emailValue) && check && passValue.length > 8 && (passValue === pass2Value)) {
            setValidation(false);
        } else {
            setValidation(true);
        }
    }, [loginValue, emailValue, passValue, pass2Value]);

    const changeLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setLoginValue(text);
    };

    const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setEmailValue(text);
    };

    const changePass = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setPassValue(text);
    };

    const changePass2 = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setPass2Value(text);
    };

    const registerHandler = () => {
        dispatch<any>(registerUser(emailValue, passValue)).then((res: ISecurityData) => {
            if (res.token) {
                setCookie('PlayAirAuth', res.token, 395);
                dispatch<any>(getCurrentUser(res.token)).then(() => {
                    history('/profile/me');
                });
            }
        });
    };

    const handleVerify = (token: string) => {
        //
    };

    const changeCheckbox = () => {
        setCheck(state => !state);
    };

    return (
        <Wrapper>
            <PageTitle h1="Rejestracja" h2="Wpisz dane a następnie uzupełnij swój profil" />
            <TextComponent value={loginValue} label="Login" onChange={changeLogin} />
            <TextComponent value={emailValue} label="Email" onChange={changeEmail} />
            <TextComponent value={passValue} label="Hasło" onChange={changePass} type="password" />
            <TextComponent value={pass2Value} label="Powtórz hasło" onChange={changePass2} type="password" />
            <Checkbox field="contact" checked={check} onChange={changeCheckbox} label="Rejestrując się zgadzasz się na przechowywanie i przetwarzanie twoich danych przez tę witrynę." />
            <GoogleReCaptcha onVerify={handleVerify} />
            <Button onClick={registerHandler} disabled={validation}>Zarejestruj</Button>
        </Wrapper>
    );
};