import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';

import { getSingleArena, saveNewArena, updateArena } from '../../actions/arenasAction';
import { IArena } from '../../entities/IArena';
import { IAddress, ISingleTicket } from '../../entities/IGlobal';
import { IReplica } from '../../entities/IReplica';
import { emptyArena } from '../../tools/emptyObjects/emptyArena';
import { Dropzone } from '../common/Dropzone/Dropzone';
import { Checkbox } from '../common/Inputs/Checkbox';
import { DatePicker } from '../common/Inputs/DatePicker';
import { TextComponent } from '../common/Inputs/TextComponent';
import { MultiObjectArea } from '../common/MultiObjectArea/MultiObjectArea';
import { ReplicasZone } from '../common/Replicas/ReplicasZone';
import { SingleReplica } from '../common/Replicas/SingleReplica';
import { RichTextEditor } from '../common/RichText/RichTextEditor';
import { Row } from '../common/Row';
import { SinglerTicket } from '../common/SingleTicket';
import { Avatar } from '../common/Avatar';
import { IUserProfile } from '../../entities/IUserProfile';
import { IUserProfileReducer } from '../../reducers/profileReducer';
import { IState } from '../../reducers';
import { PageTitle } from '../common/PageTitle';
import { colorStack } from '../../style/colorStack';
import { AddressComponent } from '../common/Inputs/AddressComponent';
import { ActionMenu } from '../common/ActionMenu';
import { ImageCropper } from '../common/ImageCropper';
import { emptyReplica } from '../../tools/emptyObjects/emptyReplica';

const Wrapper = styled.div`
    position: relative;
`;

export const EditArena: FC = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [arenaObj, setArenaObj] = useState<IArena>(emptyArena);
    const { userProfile } = useSelector<IState, IUserProfileReducer>(state => state.profile);
    const [replicaObj, setReplicaObj] = useState<IReplica>(emptyReplica);

    useEffect(() => {
        if (id) {
            dispatch<any>(getSingleArena(id)).then((res: IArena) => setArenaObj(res));
        } else {
            setArenaObj({ ...emptyArena, headMaster: userProfile, email: userProfile?.email || '' });
        }
    }, [id]);

    const changeData = (field: string, data: any) => {
        setArenaObj(state => ({ ...state, [field]: data }));
    };

    const changeDefaulData = (field: string, data: any) => {
        setArenaObj(state => ({
            ...state,
            defaultData: { ...state.defaultData, [field]: data }
        }));
    };

    const saveSection = () => {
        setArenaObj(state => state ? {...state, availableReplicasForRental: state.availableReplicasForRental ? [...state.availableReplicasForRental, replicaObj] : [replicaObj]} : state);
        setReplicaObj(emptyReplica);
    };

    const cancelHandler = () => {
        history('/arenas');
    };

    const saveHandler = () => {
        if (id) {
            dispatch<any>(updateArena(id, arenaObj));
        } else {
            dispatch<any>(saveNewArena(arenaObj));
        }
    };

    const changeAddress = (address: IAddress) => {
        changeData('address', address);
    };

    return (
        <Wrapper>
            <ActionMenu>
                <FontAwesomeIcon icon={faBan} onClick={cancelHandler} />
                <FontAwesomeIcon icon={faFloppyDisk} onClick={saveHandler} />
            </ActionMenu>
            {id ? (
                <PageTitle h1="Edytuj arenę" h2="Edytuj informacje o swojej arenie" />
            ) : (
                <PageTitle h1="Nowa" h2="Stwórz now arenę" />
            )}
            <ImageCropper label="Logo" />
            <Row numberOfElements={2}>
                <TextComponent value={arenaObj.name} onChange={(e) => changeData('name', e.target.value)} label="Nazwa" required />
                <Avatar<IUserProfile>
                    id={arenaObj.headMaster?.id || ''}
                    name={`${arenaObj.headMaster?.firstName || ''} ${arenaObj.headMaster?.lastName || ''}`}
                    pictureUrl="http://fubar13th.pl/img/logo.png"
                    data={arenaObj.headMaster || undefined}
                    label="Właściciel" required
                />
            </Row>
            <Row numberOfElements={3}>
                <TextComponent value={arenaObj.email} onChange={(e) => changeData('email', e.target.value)} label="Email kontaktowy" required />
                <DatePicker onlyDate value={arenaObj.startDate || new Date()} label="Data utworzenia" onChange={(date) => changeData('', date)} />
                <AddressComponent address={arenaObj.address ? arenaObj.address : undefined} onSelect={changeAddress} label="Adres" required />
            </Row>
            <RichTextEditor label="Opis" value="" onChange={() => { /** */ }} />
            <Dropzone label="Zdjęcia" />
            <MultiObjectArea<IReplica>
                value={arenaObj?.availableReplicasForRental || []}
                label="Repliki dostępne do wypożyczenia"
                saveHandler={saveSection}
                singleElem={SingleReplica}
            >
                <ReplicasZone replica={replicaObj} setReplicaObj={setReplicaObj}/>
            </MultiObjectArea>
            <Row numberOfElements={3}>
                <TextComponent value="" onChange={(e) => changeData('fb', e.target.value)} label="Link do facebook" />
                <TextComponent value="" onChange={(e) => changeData('insta', e.target.value)} label="Link do instagram" />
                <TextComponent value="" onChange={(e) => changeData('tw', e.target.value)} label="Link do twitter" />
            </Row>
            <Row numberOfElements={2}>
                <TextComponent value="" onChange={(e) => changeData('yt', e.target.value)} label="Link do youtube" />
                <TextComponent value="" onChange={(e) => changeData('www', e.target.value)} label="Link do www" />
            </Row>
            <Row numberOfElements={2}>
                <TextComponent value="" onChange={(e) => changeData('', e.target.value)} label="Stali bywalcy" />
                <TextComponent value="" onChange={(e) => changeData('', e.target.value)} label="Stałe grupy" />
            </Row>
            <Row numberOfElements={2}>
                <TextComponent value="" onChange={(e) => changeData('', e.target.value)} label="Zbanowani użytkownicy" />
                <TextComponent value={arenaObj?.defaultData?.playerLimit?.toString()} onChange={(e) => changeDefaulData('playerLimit', e.target.value)} label="Limit użytkowników" />
            </Row>
            <Row numberOfElements={5}>
                <Checkbox field="legal" checked={arenaObj?.defaultData?.legal || false} onChange={(e) => changeDefaulData('legal', e.target.checked)} label="Czy legalna" />
                <Checkbox field="chrono" checked={arenaObj?.defaultData?.chrono || false} onChange={(e) => changeDefaulData('chrono', e.target.checked)} label="Chronowanie obowiązkowe" />
                <Checkbox field="parking" checked={arenaObj?.defaultData?.parking || false} onChange={(e) => changeDefaulData('parking', e.target.checked)} label="Dostępny parking" />
                <Checkbox field="onlyBioBalls" checked={arenaObj?.defaultData?.onlyBioBalls || false} onChange={(e) => changeDefaulData('onlyBioBalls', e.target.checked)} label="Kulki tylko BIO" />
                <Checkbox field="ageLimit" checked={arenaObj?.defaultData?.ageLimit || false} onChange={(e) => changeDefaulData('ageLimit', e.target.checked)} label="Limit wieku" />
            </Row>
            <MultiObjectArea<ISingleTicket>
                value={arenaObj?.defaultData?.ticketPrice}
                label="Bilety"
                saveHandler={saveSection}
                singleElem={SinglerTicket}
            >
                <Row numberOfElements={2}>
                    <TextComponent value="" onChange={(e) => changeData('', e.target.value)} label="Nazwa biltetu" />
                    <TextComponent value="" onChange={(e) => changeData('', e.target.value)} label="Cena" />
                </Row>
                <RichTextEditor label="Opis" value="" onChange={() => { /** */ }} />
            </MultiObjectArea>
        </Wrapper>
    );
};