import { FC } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faLocationDot, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { colorStack } from '../../style/colorStack';
import { IEvent } from '../../entities/IEvent';
import { Pill } from './Pill';
import { media } from '../../style/breakpoints';
import { Button } from './Buttons/Button';
import { createReservation, getAllReservation } from '../../actions/reservationActions';
import { EReservationStatus, IReservation } from '../../entities/IReservation';
import { IState } from '../../reducers';
import { IUserProfileReducer } from '../../reducers/profileReducer';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 2rem;
    border: 1px solid ${colorStack.darkGrey2};
    border-top: none;
    border-radius: 0 0 8px 8px;
    padding: 1rem;
    ${media.desktop`
        flex-direction: row;
    `};
    a {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        ${media.desktop`
            flex-direction: row;
        `};
    }
`;

const Left = styled.div`
    img {
        width: 100%;
    }
    ${media.desktop`
        width: 200px;
        margin-right: 2rem;
        img {
            width: 200px;
        }
    `}
`;

const Right = styled.div`
    ${media.desktop`
        width: calc(100% - 200px - 2rem);
        display: flex;
        justify-content: space-between;
    `}
`;

const ArenaName = styled.div`
    font-size: 1.5rem;
    color: ${colorStack.blue};
    font-weight: 700;
`;

const Address = styled.div`
    font-size: 1.1rem;
    margin-top: .5rem;
    color: ${colorStack.darkGrey};
    svg {
        margin-right: 1rem;
    }
`;

const Description = styled.div`
    font-size: 1rem;
    margin: 1rem 0;
    color: ${colorStack.darkGrey};
`;

const Meta = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

const MetaBox = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
`;

const Dates = styled.div`
    display: flex;
    align-items: center;
    color: ${colorStack.darkGrey};
    font-size: 1.1rem;
    margin-top: 1rem;
    svg {
        font-size: 1.1rem;
        margin-right: 1rem;
    }
`;

const Buttons = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1rem;
    justify-content: space-between;
    flex-wrap: wrap;
    > div {
        margin-bottom: 1rem;
        margin-right: 1rem;
    }
    ${media.desktop`
        flex-direction: column;
    `}
`;

interface IProps {
    event: IEvent;
}

export const SingleEventBox: FC<IProps> = props => {
    const dispatch = useDispatch();
    const { userProfile } = useSelector<IState, IUserProfileReducer>(state => state.profile);

    const addReservation = () => {
        if (userProfile) {
            const body: IReservation = {
                status: EReservationStatus.ACCEPTER,
                user: userProfile,
                event: props.event,
                id: ''
            };
            dispatch<any>(createReservation(body));
        }
        dispatch<any>(getAllReservation());
    };

    const waitingReservation = () => {
        if (userProfile) {
            const body: IReservation = {
                status: EReservationStatus.PENDING,
                user: userProfile,
                event: props.event,
                id: ''
            };
            dispatch<any>(createReservation(body));
        }
    };

    const interestingReservation = () => {
        if (userProfile) {
            const body: IReservation = {
                status: EReservationStatus.INTERESTED,
                user: userProfile,
                event: props.event,
                id: ''
            };
            dispatch<any>(createReservation(body));
        }
    };

    return (
        <Wrapper>
            <Left>
                <Buttons>
                    <Button onClick={addReservation}>Wezmę udział</Button>
                    <Button onClick={waitingReservation}>Może wezmę udział</Button>
                    <Button onClick={interestingReservation}>Zainteresowany</Button>
                </Buttons>
            </Left>
            <Right>
                <MetaBox>
                    <div>
                        <ArenaName>
                            {props.event.name}
                        </ArenaName>
                        <Address>
                            <FontAwesomeIcon icon={faLocationDot} />{props.event.address?.zipCode} {props.event.address?.city}, {props.event.address?.street} {props.event.address?.number}
                        </Address>
                        <Address>
                            <FontAwesomeIcon icon={faPhone} />{props.event.address?.phone}
                        </Address>
                        <Description>
                            {props.event.shortDescription}
                        </Description>
                    </div>
                    <Meta>
                        {props.event.defaultData?.playerLimit && <Pill text={props.event.defaultData?.playerLimit.toString()} label="Limit graczy" />}
                        {props.event.defaultData?.legal && <Pill label="Legalna" />}
                        {props.event.defaultData?.chrono && <Pill label="Chrono" />}
                        {props.event.defaultData?.parking && <Pill label="Parking" />}
                        {props.event.defaultData?.onlyBioBalls && <Pill label="Kulki BIO" />}
                    </Meta>
                    <Dates>
                        <FontAwesomeIcon icon={faCalendarDays} />
                        <span>{moment(props.event.startDate).format('LL, h:mm')} - {moment(props.event.endDate).format('LL, h:mm')}</span>
                    </Dates>
                </MetaBox>
            </Right>
        </Wrapper>
    );
};