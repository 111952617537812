import { Dispatch } from 'redux';

import { GET_USER_PROFILE } from './types/userProfileTypes';
import { IUserProfile } from '../entities/IUserProfile';
import { IState } from '../reducers';

export const getCurrentUser = (currentToken?: string): Promise<IUserProfile> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch('http://d2kqdrigyj90hu.cloudfront.net/api/user/me', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': currentToken || token as string
        }
    }).then((article: any) => article.json()).then((response: IUserProfile) => {
        dispatch({
            type: GET_USER_PROFILE,
            userProfile: response
        });
        return response;
    });
}) as any;

export const getUserById = (userId: string): Promise<IUserProfile> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`http://d2kqdrigyj90hu.cloudfront.net/api/user/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token as string
        }
    }).then((article: any) => article.json()).then((response: IUserProfile) => {
        dispatch({
            type: GET_USER_PROFILE,
            userProfile: response
        });
        return response;
    });
}) as any;

export const searchUsers = (): Promise<IUserProfile> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`http://d2kqdrigyj90hu.cloudfront.net/api/user`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token as string
        }
    });
}) as any;

export const updateUserData = (userId: string | undefined, body: IUserProfile | undefined): Promise<IUserProfile> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`http://d2kqdrigyj90hu.cloudfront.net/api/user/${userId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token as string
        },
        body: JSON.stringify(body)
    }).then((article: any) => article.json()).then((response: IUserProfile) => {
        if (response.id) {
            dispatch({
                type: GET_USER_PROFILE,
                userProfile: response
            });
        }
        return response;
    });
}) as any;