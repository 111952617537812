import { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../style/colorStack';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colorStack.darkGrey};
    text-align: center;
    font-size: 1.5rem;
    flex-direction: column;
    img {
        width: 450px;
        max-width: 90%;
    }
`;

export const Soon: FC = () => {
    return (
        <Wrapper>
            <br />
            <br />
            <img src={`${process.env.PUBLIC_URL}/img/logo_white.png`} alt="PlayAir logo" />
            <br />
            <br />
            Strona w trakcie budowy.
            <br />
            <br />
            Już niebawem będziesz mógł w pełni korzystać z naszych usług
        </Wrapper>
    );
};