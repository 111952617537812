import { FC } from 'react';
import styled from 'styled-components';

import { ISingleTicket } from '../../entities/IGlobal';
import { media } from '../../style/breakpoints';
import { colorStack } from '../../style/colorStack';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    border-bottom: 1px solid ${colorStack.darkGrey2};
    margin-bottom: 2rem;
    padding-bottom: 1rem;
`;

const Name = styled.div`
    font-size: 1.3rem;
    color: ${colorStack.blue};
    font-weight: 500;
    margin-bottom: 1rem;
`;

const Meta = styled.div`
    margin-bottom: .5rem;
`;

interface IProps {
    data: ISingleTicket;
}

export const SinglerTicket: FC<IProps> = props => {
    return (
        <Wrapper>
            <Name>{props.data?.name} {props.data?.price}PLN</Name>
            <Meta dangerouslySetInnerHTML={{__html: props.data?.description}}/>
        </Wrapper>
    );
};