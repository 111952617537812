import { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../style/colorStack';

const Wrapper = styled.div`
    position: absolute!important;
    display: flex;
    flex-direction: column;
    z-index: 9;
    background: ${colorStack.white};
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 8px 32px rgba(0,0,0, .2);
    top: 40px;
    border: 1px solid ${colorStack.darkGrey2};
    button {
        padding: .5rem 1rem;
        border: none;
        background: none;
        cursor: pointer;
        background: ${colorStack.white};
        color: ${colorStack.darkGrey};
        transition: all .3s;
        width: 100%;
        white-space: nowrap;
        border-bottom: 1px solid ${colorStack.darkGrey2};
        &:last-child {
            border-bottom: none;
        }
        &:hover {
            color: ${colorStack.white};
            background: ${colorStack.blue};
        }
    }
`;

interface IProps {
    links: {
        label: string;
        action(): void;
    }[];
}

export const ContextMenu: FC<IProps> = props => {
    return (
        <Wrapper>
            {props.links?.map(link => (
                <button type="button" key={link.label} onClick={link.action}>{link.label}</button>
            ))}
        </Wrapper>
    );
};