import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { faCalendarPlus, faChartArea, faPen } from '@fortawesome/free-solid-svg-icons';

import { getSingleArena } from '../../actions/arenasAction';
import { IArena } from '../../entities/IArena';
import { NoData } from '../common/NoData';
import { SingleArenaBox } from '../common/SingleArenaBox';
import { ActionMenu } from '../common/ActionMenu';
import { MultiObjectArea } from '../common/MultiObjectArea/MultiObjectArea';
import { IReplica } from '../../entities/IReplica';
import { SingleReplica } from '../common/Replicas/SingleReplica';
import { IUserProfileReducer } from '../../reducers/profileReducer';
import { IState } from '../../reducers';
import { SinglerTicket } from '../common/SingleTicket';
import { ISingleTicket, ISocial } from '../../entities/IGlobal';
import { colorStack } from '../../style/colorStack';

const Wrapper = styled.div`
    position: relative;
`;

const Description = styled.div`
    font-size: 1rem;
    margin: 1rem 0;
    color: ${colorStack.darkGrey};
    h1 {
        margin: 1rem 0;
        font-size: 2rem;
    }
    h2 {
        margin: .5rem 0;
        font-size: 1.6rem;
    }
    h2 {
        margin: .5rem 0;
        font-size: 1.3rem;
    }
    p {
        font-size: 1rem;
        margin: .3rem 0;
    }
`;

const BottemBox = styled.div``;

export const SingleArena: FC = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [arena, setArena] = useState<IArena | undefined>(undefined);
    const { userProfile } = useSelector<IState, IUserProfileReducer>(state => state.profile);

    useEffect(() => {
        if (id) {
            dispatch<any>(getSingleArena(id)).then((res: IArena) => setArena(res));
        }
    }, [id]);

    const addNewEvent = () => {
        history(`/edit-event`);
    };

    const editCurrentArena = () => {
        history(`/edit-arena/${id}`);
    };

    const openArenaAdmin = () => {
        history(`/arena/${id}/admin`);
    };

    return (
        <Wrapper>
            <ActionMenu>
                <FontAwesomeIcon icon={faCalendarPlus} onClick={addNewEvent} />
                {arena?.headMaster?.id === userProfile?.id &&
                    <>
                        <FontAwesomeIcon icon={faPen} onClick={editCurrentArena} />
                        <FontAwesomeIcon icon={faChartArea} onClick={openArenaAdmin} />
                    </>
                }
                {arena?.social?.find(elem => elem.type === ISocial.FACEBOOK) && (
                    <a href={`${arena?.social?.find(elem => elem.type === ISocial.FACEBOOK)?.value}`} target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" /></svg>
                    </a>
                )}
                {arena?.social?.find(elem => elem.type === ISocial.INSTAGRAM) && (
                    <a href={`${arena?.social?.find(elem => elem.type === ISocial.INSTAGRAM)?.value}`} target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                    </a>
                )}
                {arena?.social?.find(elem => elem.type === ISocial.TWITTER) && (
                    <a href={`${arena?.social?.find(elem => elem.type === ISocial.TWITTER)?.value}`} target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" /></svg>
                    </a>
                )}
                {arena?.social?.find(elem => elem.type === ISocial.WEBSITE) && (
                    <a href={`${arena?.social?.find(elem => elem.type === ISocial.WEBSITE)?.value}`} target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 21 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z" /></svg>
                    </a>
                )}
                {arena?.social?.find(elem => elem.type === ISocial.YOUTUBE) && (
                    <a href={`${arena?.social?.find(elem => elem.type === ISocial.YOUTUBE)?.value}`} target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" /></svg>
                    </a>
                )}
            </ActionMenu>
            {arena ? (
                <>
                    <SingleArenaBox arena={arena} />
                    <Description dangerouslySetInnerHTML={{ __html: arena.description }} />
                    <BottemBox>
                        <MultiObjectArea<IReplica>
                            value={arena?.availableReplicasForRental || []}
                            label="Repliki dostępne do wypożyczenia"
                            singleElem={SingleReplica}
                            onlyView
                        >
                            <></>
                        </MultiObjectArea>
                        <MultiObjectArea<ISingleTicket>
                            value={arena?.defaultData?.ticketPrice}
                            label="Bilety"
                            onlyView
                            singleElem={SinglerTicket}
                        >
                            <></>
                        </MultiObjectArea>
                    </BottemBox>
                </>
            ) : (
                <NoData>Przykro nam ale nie znaleźliśmy takiej areny</NoData>
            )}
        </Wrapper>
    );
};