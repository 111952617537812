import reset from 'styled-reset';
import { createGlobalStyle } from 'styled-components';

import { colorStack } from './colorStack';
import { media } from './breakpoints';

export const GlobalStyle = createGlobalStyle`
    ${reset}
    * {
        box-sizing: border-box;
    }
    html {
        font-size: 16px;
    }

    body {
        background: ${colorStack.white};
        font-family: 'Roboto', sans-serif;
        color: ${colorStack.darkGrey};
    }
    a, p {
        font-size: 1rem;
        text-decoration: none;
    }
    strong {
        font-weight: 700;
    }
`;