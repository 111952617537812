import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { loginUser, setToken } from '../../actions/securityActions';
import { getCurrentUser } from '../../actions/userProfileActions';
import { colorStack } from '../../style/colorStack';
import { Button } from '../common/Buttons/Button';
import { TextComponent } from '../common/Inputs/TextComponent';
import { setCookie } from '../../tools/cookies';
import { ISecurityData } from '../../entities/IGlobal';
import { PageTitle } from '../common/PageTitle';

const Wrapper = styled.div``;

const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    > span {
        color: ${colorStack.blue};
        font-size: 0.9rem;
        cursor: pointer;
    }
`;

export const Login: FC = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const [loginValue, setLoginValue] = useState<string>('');
    const [passValue, setPassValue] = useState<string>('');
    const [validation, setValidation] = useState<boolean>(true);

    useEffect(() => {
        if (loginValue && passValue && passValue.length > 8) {
            setValidation(false);
        } else {
            setValidation(true);
        }
    }, [loginValue, passValue]);

    const changeLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setLoginValue(text);
    };

    const changePass = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setPassValue(text);
    };

    const loginHandler = () => {
        dispatch<any>(loginUser(loginValue, passValue)).then((res: ISecurityData) => {
            setCookie('PlayAirAuth', res.token, 395);
            dispatch<any>(setToken(res.token));
            dispatch<any>(getCurrentUser(res.token)).then(() => {
                history('/profile/me');
            });
        });
    };

    return (
        <Wrapper>
            <PageTitle h1="Logowanie" h2="Wpisz email i hasło" />
            <TextComponent value={loginValue} label="Login" onChange={changeLogin} />
            <TextComponent value={passValue} label="Hasło" type="password" onChange={changePass} />
            <Buttons>
                <Button onClick={loginHandler} disabled={validation}>Zaloguj</Button>
                <span onClick={() => history('/get-password')}>Nie pamiętam hasła</span>
            </Buttons>
        </Wrapper>
    );
};