import { faCircleUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colorStack } from '../../style/colorStack';
import { Label } from './Inputs/Label';

const Wrapper = styled.div`
    margin-bottom: 1rem;
`;

const Name = styled.div`
    color: ${colorStack.darkGrey};
    font-size: 1rem;
    margin-left: 1rem;
`;

const Content = styled(Link)`
    display: inline-flex;
    align-items: center;
    height: 35px;
    background: ${colorStack.darkGrey2};
    padding-right: 1rem;
    border-radius: 50px;
    img, svg {
        width: 33px;
        font-size: 1.9rem;
        border-radius: 100%;
        color: ${colorStack.white};
    }
`;

interface IProps<T> {
    id: string;
    label?: string;
    required?: boolean;
    name: string;
    pictureUrl: string | undefined;
    data: T | undefined;
}

export const Avatar = <T extends object = {}>(props: IProps<T>) => {
    return (
        <Wrapper>
            {props.label && <Label required={props.required} label={props.label} />}
            <Content to={`/profile/${props.id}`}>
                {props?.pictureUrl ? (
                    <img src={props?.pictureUrl} alt="User profile" />
                ) : (
                    <FontAwesomeIcon icon={faCircleUser} />
                )}
                <Name>{props.name}</Name>
            </Content>
        </Wrapper>
    );
};