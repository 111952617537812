import { FC } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../style/colorStack';

const Wrapper = styled.div`
    position: relative;
    > div {
        display: none;
        position: absolute;
        padding: 10px;
        border-radius: 4px;
        font-size: 1rem;
        background: ${colorStack.white};
        color: ${colorStack.darkGrey};
    }
    &:hover {
        > div {
            display: block;
            z-index: 99;
        }
    }
`;

const Text = styled.span<{ numberOfLines: number }>`
    width: 100%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    ${props => props.numberOfLines > 1 && css`
        display: -webkit-box !important;
        white-space: normal;
        -webkit-line-clamp: ${props.numberOfLines};
    `}
`;

interface IProps {
    numberOfLines: number;
    children: React.ReactNode;
}

export const MultilineTextWithEllipsis: FC<IProps> = props => {
    return (
        <Wrapper>
            <Text numberOfLines={props.numberOfLines}>
                {props.children}
            </Text>
        </Wrapper>
    );
};