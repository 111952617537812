import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import moment from 'moment';
import 'moment/locale/pl';

import { Layout } from './components/common/Layout';
import { GlobalStyle } from './style/global';
import store from './tools/store';
import { Navigation } from './components/routes';

moment.locale('pl');

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <GoogleReCaptchaProvider
        reCaptchaKey="6Ld9MUIlAAAAAHSHN-iVw-2naj28NlXTcjsCzA3_"
        language="PL"
        useRecaptchaNet
    >
        <Provider store={store}>
            <GlobalStyle />
            <BrowserRouter>
                <Layout>
                    <Navigation />
                </Layout>
            </BrowserRouter>
        </Provider>
    </GoogleReCaptchaProvider>
);
