import { FC } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faPeopleGroup, faPlaceOfWorship, faUserPlus, faCalendarDay } from '@fortawesome/free-solid-svg-icons';

import { colorStack } from '../../style/colorStack';
import { media } from '../../style/breakpoints';

const Wrapper = styled.div``;

const Box = styled.div<{ right?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid ${colorStack.darkGrey2};
    padding: 0 0 2rem 0;
    margin: 0 0 2rem 0;
    ${media.desktop`
        flex-direction: row;
    `}
    ${props => props.right && css`
        ${media.desktop`
            flex-direction: row-reverse;
        `}
    `};
    > div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        ${media.desktop`
            flex-direction: row;
            width: calc(100% - 280px - 4rem);
        `}
    }
    > svg {
        color: ${colorStack.green};
        width: 140px;
        ${props => props.right && css`
            margin: 0 0 0 2rem;
        `};
        ${media.desktop`
            margin: 0 2rem 0 0;
        `}
    }
`;

const List = styled.ul`
    list-style: none;
    line-height: 2rem;
    li {
        color: ${colorStack.darkGrey};
        &::before {
            content: "*";
            display: inline-block;
            color: ${colorStack.blue};
            margin-right: .5rem;
        }
    }
`;

const Org = styled.div`
    ${media.desktop`
        width: 48%;
    `}
`;
const User = styled.div`
    margin-bottom: 2rem;
    ${media.desktop`
        margin-bottom: 0;
        width: 48%;
    `}
`;
const Who = styled.div`
    font-weight: 500;
    margin-bottom: .5rem;
`;

export const Home: FC = () => {
    return (
        <Wrapper>
            <Box>
                <FontAwesomeIcon icon={faUserPlus} size="10x" />
                <div>
                    <User>
                        <Who>Dla użytkownika</Who>
                        <List>
                            <li>Statystyki</li>
                            <li>Rozbudowany profil</li>
                            <li>Sprawdzone areny</li>
                            <li>Proste zapisywanie się/tworzenie eventów</li>
                            <li>Wyszukiwarka aren</li>
                        </List>
                    </User>
                    <Org>
                        <Who>Dla organizatora</Who>
                        <List>
                            <li>Statystyki uczęszczania</li>
                            <li>Proste zapisywanie się</li>
                            <li>Sprawdzeni gracze</li>
                        </List>
                    </Org>
                </div>
            </Box>
            <Box right>
                <FontAwesomeIcon icon={faList} size="10x" />
                <div>
                    <User>
                        <Who>Dla użytkownika</Who>
                        <List>
                            <li>Wiesz z kim grasz</li>
                        </List>

                    </User>
                    <Org>
                        <Who>Dla organizatora</Who>
                        <List>
                            <li>Lista uczestników imprezy</li>
                            <li>Możliwość sprawdzania obecności</li>
                            <li>Możliwość sprawdzenia gracza</li>
                        </List>
                    </Org>
                </div>
            </Box>
            <Box>
                <FontAwesomeIcon icon={faPeopleGroup} size="10x" />
                <div>
                    <User>
                        <Who>Dla użytkownika</Who>
                        <List>
                            <li>Rozbudowany profil grupy</li>
                            <li>Uproszczony zapis z pozycji grupy</li>
                        </List>

                    </User>
                    <Org>
                        <Who>Dla organizatora</Who>
                        <List>
                            <li>Możliwość sprawdzenia grupy</li>
                        </List>
                    </Org>
                </div>
            </Box>
            <Box right>
                <FontAwesomeIcon icon={faPlaceOfWorship} size="10x" />
                <div>
                    <User>
                        <Who>Dla użytkownika</Who>
                        <List>
                            <li>Tylko sprawdzone areny</li>
                            <li>Statystyki areny</li>
                            <li>Mapa areny</li>
                            <li>Możliwość zapisu na wydarzenie</li>
                            <li>Możliwość zapytania o termin na wydarzenie prywatne</li>
                        </List>
                    </User>
                    <Org>
                        <Who>Dla organizatora</Who>
                        <List>
                            <li>Statystyki areny</li>
                            <li>Automatyczny kalendarz</li>
                        </List>
                    </Org>
                </div>
            </Box>
            <Box>
                <FontAwesomeIcon icon={faCalendarDay} size="10x" />
                <div>
                    <User>
                        <Who>Dla użytkownika</Who>
                        <List>
                            <li>Tylko sprawdzone eventy</li>
                            <li>Możliwość zapisu na event</li>
                        </List>
                    </User>
                    <Org>
                        <Who>Dla organizatora</Who>
                        <List>
                            <li>Statystyki eventu</li>
                            <li>Szybka lista uczestników</li>
                            <li>Sprawdzeni gracze</li>
                        </List>
                    </Org>
                </div>
            </Box>
        </Wrapper>
    );
};