import { FC, useState } from 'react';
import styled from 'styled-components';
import ReactQuill, { UnprivilegedEditor } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Label } from '../Inputs/Label';
import { colorStack } from '../../../style/colorStack';

const Wrapper = styled.div`
    margin-bottom: 1rem;
    width: 100%;
    .quill {
        .ql-toolbar {
            border-radius: 8px 8px 0 0;
            border: 2px solid ${colorStack.darkGrey2};
        }
        .ql-container {
            border-radius: 0 0 8px 8px;
            border: 2px solid ${colorStack.darkGrey2};
            min-height: 150px;
        }
    }
`;

const modules = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image'],
        ['clean']
    ]
};

interface IProps {
    label?: string;
    value: string;
    required?: boolean;
    onChange(text: string): void;
}

export const RichTextEditor: FC<IProps> = props => {

    const changeHandler = (text: string, delta: any, source: any, editor: UnprivilegedEditor) => {
        console.log('text', text);
        props.onChange(text);
    };
    return (
        <Wrapper>
            {props.label && <Label required={props.required} label={props.label} />}
            <ReactQuill
                theme="snow"
                value={props.value}
                modules={modules}
                onChange={changeHandler}
            />
        </Wrapper>
    );
};