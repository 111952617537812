import { FC } from 'react';
import styled from 'styled-components';

import { SimpleBox } from '../common/SimpleBox';
import { NoData } from '../common/NoData';

const Wrapper = styled.div``;

export const MyFriends: FC = () => {
    return (
        <Wrapper>
            <SimpleBox>
                <NoData>Nie posiadasz jeszcze żadnych przyjaciół. Możesz ich dodać wchodząc na profil użytkownika, którego chcesz polubić.</NoData>
            </SimpleBox>
        </Wrapper>
    );
};