import { IEvent } from '../../entities/IEvent';

export const emptyEvent: IEvent = {
    name: '',
    id: '',
    address: undefined,
    headMaster: undefined,
    startDate: new Date(),
    endDate: new Date(),
    shortDescription: '',
    duration: 0,
    description: '',
    arena: undefined,
    social: [],
    pictureUrl: '',
    photos: [],
    rating: 0,
    teams: [],
    signedMembers: [],
    interestedMembers: [],
    declainedMembers: [],
    disbledMambers: [],
    replicasLimits: undefined,
    defaultData: {
        playerLimit: 0,
        legal: false,
        chrono: false,
        parking: false,
        onlyBioBalls: false,
        ageLimit: false,
        ticketPrice: []
    }
};