import { FC } from 'react';
import styled from 'styled-components';
import Dropdown, { ReactDropdownProps } from 'react-dropdown';
import 'react-dropdown/style.css';

import { Label } from './Inputs/Label';
import { colorStack } from '../../style/colorStack';

const Wrapper = styled.div`
    margin-bottom: 1rem;
    .Dropdown-control {
        border-radius: 4px;
        border: 2px solid ${colorStack.darkGrey2};
    }
    .Dropdown-disabled {
        opacity: .5;
    }
`;

type IProps = ReactDropdownProps & {
    label?: string;
    required?: boolean;
};

export const DropDown: FC<IProps> = props => {
    return (
        <Wrapper>
            {props.label && <Label required={props.required} label={props.label}/>}
            <Dropdown {...props} />
        </Wrapper>
    );
};