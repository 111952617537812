import { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../style/colorStack';

const Wrapper = styled.div`
    max-width: 100%;
    display: flex;
    white-space: nowrap;
    margin: 0 0.5rem 0.5rem 0;
    align-items: stretch;
    height: 30px;
    border: 1px solid ${colorStack.blue};
    border-radius: 50px;
    overflow: hidden;
    span {
        padding: 0 16px;
        display: flex;
        align-items: center;
        font-size: .9rem;
        background: ${colorStack.white};
        color: ${colorStack.green};
        &:last-child {
            background: ${colorStack.blue};
            color: ${colorStack.white};
        }
    }
`;

interface IProps {
    text?: string;
    label: string;
}

export const Pill: FC<IProps> = props => {
    return (
        <Wrapper>
            <span>{props.label}</span>
            {props.text && <span>{props.text}</span>}
        </Wrapper>
    );
};