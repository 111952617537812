import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { SimpleBox } from '../common/SimpleBox';
import { NoData } from '../common/NoData';
import { Button } from '../common/Buttons/Button';

const Wrapper = styled.div``;

export const MyArenas: FC = () => {
    const history = useNavigate();

    const addNewArena = () => {
        history('/edit-arena');
    };

    return (
        <Wrapper>
            <SimpleBox>
                <NoData>Nie dołączyłeś jeszcze do żadnej areny lub nie jesteś właścicielem żadnej.</NoData>
                <Button onClick={addNewArena}>Dodaj nową arenę</Button>
            </SimpleBox>
        </Wrapper>
    );
};