import { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../style/colorStack';

const Wrapper = styled.div`
    color: ${colorStack.darkGrey};
    font-size: 1rem;
    margin-bottom: 4px;
    font-weight: 500;
`;

const Required = styled.span`
    color: ${colorStack.red};
    margin-left: 4px;
`;

interface IProps {
    label: string;
    required?: boolean;
}

export const Label: FC<IProps> = props => {
    return (
        <Wrapper>{props.label}{props.required && <Required>*</Required>}</Wrapper>
    );
};