import * as actionTypes from '../actions/types/userProfileTypes';
import { LOGOUT } from '../actions/types/securityTypes';
import { ISocial } from '../entities/IGlobal';
import { IUserProfile } from '../entities/IUserProfile';

export interface IUserProfileReducer {
    userProfile: IUserProfile | undefined;
}

/* const mockUserProfile: IUserProfile = {
    firstName: 'Mateusz',
    lastName: 'Sowiński',
    id: '1',
    email: 'mateusz.sowinski@gmail.com',
    pictureUrl: 'http://test-dev.website/img/profiles/polej/avatar.png',
    team: {
        name: 'Fubar 13TH',
        pictureUrl: 'http://test-dev.website/img/logo.png',
        members: [],
        commander: undefined,
        startDate: '1.02.2020',
        address: undefined,
        email: '',
        id: '1',
        social: [],
        description: 'lorem ipsum samet dolore',
        rating: 4,
        blockedMembers: []
    },
    arena: undefined,
    dateOfBirth: '18.02.1988',
    address: undefined,
    replicas: [],
    nickName: 'Polej',
    social: [{
        type: ISocial.FACEBOOK,
        value: 'http://facebook'
    }, {
        type: ISocial.INSTAGRAM,
        value: 'http://facebook'
    }, {
        type: ISocial.TWITTER,
        value: 'http://facebook'
    }, {
        type: ISocial.WEBSITE,
        value: 'http://facebook'
    }, {
        type: ISocial.YOUTUBE,
        value: 'http://facebook'
    }, {
        type: ISocial.PHONE,
        value: '666 666 666'
    }],
    gearSet: [],
    newPerson: false,
    description: 'Personal Description',
    rating: 5,
    photos: [],
    medals: [],
    movies: []
}; */

const defaultState = (): IUserProfileReducer => ({
    userProfile: undefined
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState(), action: any): IUserProfileReducer => {
    switch (action.type) {

        case actionTypes.GET_USER_PROFILE: {
            const payload: actionTypes.IUserProfileTypes['GET_USER_PROFILE'] = action;
            return {
                ...state,
                userProfile: payload.userProfile
            };
        }
        case LOGOUT: {
            return {
                ...state,
                userProfile: undefined
            };
        }

        default: {
            return state;
        }
    }
};