import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Option } from 'react-dropdown';

import { EReplicaDrive, EReplicaType, IReplica } from '../../../entities/IReplica';
import { TextComponent } from '../Inputs/TextComponent';
import { Dropzone } from '../Dropzone/Dropzone';
import { DropDown } from '../DropDown';
import { CBallsSize, CCompaniesList } from '../../../entities/IGlobal';
import { Row } from '../Row';

const Wrapper = styled.div`
    margin-bottom: 1rem;
`;

interface IProps {
    replica: IReplica;
    setReplicaObj?(replica: IReplica): void;
}

export const ReplicasZone: FC<IProps> = props => {

    useEffect(() => {
        if (props.replica && props.setReplicaObj) {
            props.setReplicaObj(props.replica);
        }
    }, [props.replica]);

    const changeData = (field: string, data: string) => {
        if (props.setReplicaObj) {
            props.setReplicaObj({ ...props.replica, [field]: data });
        }
    };

    const changePower = (field: string, data: string) => {
        if (props.setReplicaObj) {
            props.setReplicaObj({
                ...props.replica, power: props.replica.power ? {
                    ...props.replica.power,
                    [field]: data
                } : props.replica.power
            });
        }
    };

    return (
        <Wrapper>
            <TextComponent value={props.replica?.name} onChange={(e) => changeData('name', e.target.value)} label="Nazwa" required />
            <Dropzone label="Dodaj zdjęcie" />
            <Row numberOfElements={2}>
                <DropDown
                    label="Kulki"
                    value={props.replica?.balls}
                    placeholder="Wybierz..."
                    options={CBallsSize}
                    onChange={(arg: Option) => changeData('balls', arg.value)}
                />
                <DropDown
                    value={props.replica?.company}
                    label="Producent"
                    placeholder="Wybierz..."
                    options={CCompaniesList}
                    onChange={(arg: Option) => changeData('company', arg.value)}
                />
            </Row>
            <TextComponent value={props.replica?.model} onChange={(e) => changeData('model', e.target.value)} label="Model" />
            <Row numberOfElements={3}>
                <TextComponent value={props.replica?.range} onChange={(e) => changeData('range', e.target.value)} label="Zasięg" />
                <TextComponent value={props.replica?.power?.fps} onChange={(e) => changePower('fps', e.target.value)} label="Moc w FPS" />
                <TextComponent value={props.replica?.power?.jouls} onChange={(e) => changePower('jouls', e.target.value)} label="Moc w Joul" />
            </Row>
            <TextComponent value={props.replica?.gearbox} onChange={(e) => changeData('gearbox', e.target.value)} label="Gearbox" />
            <Row numberOfElements={4}>
                <DropDown
                    label="Napęd"
                    value={props.replica?.drive}
                    placeholder="Wybierz..."
                    options={(Object.keys(EReplicaDrive) as (keyof typeof EReplicaDrive)[]).map(elem => ({
                        label: EReplicaDrive[elem],
                        value: elem
                    }))}
                    onChange={(arg: Option) => changeData('drive', arg.value)}
                />
                <DropDown
                    label="Typ"
                    value={props.replica?.type}
                    placeholder="Wybierz..."
                    options={(Object.keys(EReplicaType) as (keyof typeof EReplicaType)[]).map(elem => ({
                        label: EReplicaType[elem],
                        value: elem
                    }))}
                    onChange={(arg: Option) => changeData('type', arg.value)}
                />
                <TextComponent value={props.replica?.magazineCapacity} onChange={(e) => changeData('magazineCapacity', e.target.value)} label="Pojemność magazynka" />
                <TextComponent value={props.replica?.weight} onChange={(e) => changeData('weight', e.target.value)} label="Waga w kg" />
            </Row>
            <TextComponent value={props.replica?.description} onChange={(e) => changeData('description', e.target.value)} label="Opis" />
        </Wrapper>
    );
};