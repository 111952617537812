import { FC, useState } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../style/colorStack';
import { Label } from '../Inputs/Label';

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-direction: column;
    margin-bottom: 1rem;
    min-height: 150px;
    input {
        cursor: pointer;
    }
`;

const DottedArea = styled.div`
    border: 1px dashed ${colorStack.blue};
    position: relative;
    flex-direction: column;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    min-height: 150px;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    > span {
        color: ${colorStack.blue};
    }
`;

const DropArea = styled.input`
    opacity: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
`;

const DropInner = styled.div`
    padding: 0.345rem 0;
    color: ${colorStack.blue};
`;

const Files = styled.div`
    display: flex;
    flex-wrap: wrap;
    img {
        width: 100px;
        margin-right: 1rem;
    }
`;

interface IProps {
    label?: string;
    required?: boolean;
}

export const Dropzone: FC<IProps> = props => {
    const [files, setFiles] = useState<string[]>([]);

    const fileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                setFiles(state => [...state, reader.result as string]);
            };
        }
    };

    return (
        <div>
            {props.label && <Label required={props.required} label={props.label} />}
            <Wrapper>
                <DottedArea>
                    <DropInner>
                        <span>Przeciągnij pliki lub kliknij tutaj</span>
                        <DropArea id="attachment" type="file" accept="image/*" multiple={true} onChange={fileInputChange} />
                    </DropInner>
                </DottedArea>
                <Files>
                    {files.map((file, index) => (
                        <img src={file} key={index} />
                    ))}
                </Files>
            </Wrapper>
        </div>
    );
};