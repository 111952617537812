import { FC, useState } from 'react';
import styled from 'styled-components';

import { Button } from '../common/Buttons/Button';
import { TextComponent } from '../common/Inputs/TextComponent';

const Wrapper = styled.div``;

export const ChangePassword: FC = () => {
    const [oldPass, setOldPass] = useState<string>('');
    const [pass, setPass] = useState<string>('');
    const [pass2, setPass2] = useState<string>('');

    const changePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setPass(text);
    };

    const changePassword2 = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setPass2(text);
    };

    const changeOldPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setOldPass(text);
    };

    const sendPass = () => {
        //
    };

    return (
        <Wrapper>
            <TextComponent value={oldPass} onChange={changeOldPassword} type="password" label="Stare hasło" />
            <TextComponent value={pass} onChange={changePassword} type="password" label="Nowe hasło" />
            <TextComponent value={pass2} onChange={changePassword2} type="password" label="Powtórz nowe hasło" />
            <Button onClick={sendPass}>Wyślij</Button>
        </Wrapper>
    );
};