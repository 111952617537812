import { EReplicaType, IReplica } from '../../entities/IReplica';

export const emptyReplica: IReplica = {
    name: '',
    picture: '',
    balls: '',
    company: '',
    id: '',
    model: '',
    range: '',
    power: {
        fps: '',
        jouls: ''
    },
    gearbox: '',
    drive: undefined,
    type: EReplicaType.none,
    magazineCapacity: '',
    weight: '',
    description: '',
    rating: 0,
    photos: []
};