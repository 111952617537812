import { FC } from 'react';
import styled from 'styled-components';
import Scrollbars from 'react-custom-scrollbars';

import { colorStack } from '../../../style/colorStack';
import { CloseButton } from '../Buttons/CloseButton';
import { Button } from '../Buttons/Button';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0, .8);
`;

const Inner = styled.div`
    border-radius: 8px;
    position: relative;
    height: 60vh;
    max-width: 90%;
    width: 1024px;
    padding: 1rem;
    background: ${colorStack.white};
`;

const Title = styled.span`
    font-size: 1.4rem;
    color: ${colorStack.blue};
    position: relative;
`;

const Line = styled.span`
    position: absolute;
    bottom: -8px;
    left: 0;
    height: 2px;
    width: 200%;
    background: ${colorStack.darkGrey2};
`;

const ButtonsLine = styled.span`
    position: absolute;
    top: -8px;
    right: 0;
    height: 2px;
    width: 50%;
    background: ${colorStack.darkGrey2};
`;

const Content = styled.div`
    margin-top: 2rem;
    height: 43vh;
`;

const Buttons = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
`;

interface IProps {
    showPopup: boolean;
    children: React.ReactNode;
    title?: string;
    changeVisibility(): void;
    saveHandler?(): void;
}

export const Popup: FC<IProps> = props => {
    return (
        <>
            {props.showPopup ? (
                <Wrapper>
                    <Inner>
                        <CloseButton onClick={props.changeVisibility} />
                        {props.title && (
                            <Title>
                                {props.title}
                                <Line />
                            </Title>
                        )}
                        <Content>
                            <Scrollbars>
                                {props.children}
                            </Scrollbars>
                        </Content>
                        {props.saveHandler &&
                            <Buttons>
                                <ButtonsLine />
                                <Button onClick={props.saveHandler}>Zapisz</Button>
                            </Buttons>
                        }
                    </Inner>
                </Wrapper>
                // tslint:disable-next-line:no-null-keyword
            ) : null}
        </>
    );
};