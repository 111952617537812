import { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../style/colorStack';

const Wrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 25px;
    margin-bottom: 1rem;
    label {
        padding-left: 30px;
        cursor: pointer;
        font-size: .8rem;
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ span {
            background-color: ${colorStack.blue};
        }
        &:checked ~ span:after {
            display: block;
        }
    }
    span {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: ${colorStack.white};
        border: 2px solid ${colorStack.darkGrey2};
        border-radius: 4px;
        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 8px;
            top: 3px;
            width: 5px;
            height: 10px;
            border: solid ${colorStack.white};
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
    &:hover input ~ span {
        background-color: ${colorStack.blue};
    }
`;

interface IProps {
    label: string;
    field: string;
    checked: boolean;
    onChange(e: React.ChangeEvent<HTMLInputElement>): void;
}

export const Checkbox: FC<IProps> = props => {
    return (
        <Wrapper>
            <input type="checkbox" id={props.field} checked={props.checked} onChange={props.onChange}/>
            <span />
            <label htmlFor={props.field}>{props.label}</label>
        </Wrapper>
    );
};