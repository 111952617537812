import { IEvent } from './IEvent';
import { IUserProfile } from './IUserProfile';

export interface IReservation {
    id: string;
    event: IEvent;
    user: IUserProfile;
    status: EReservationStatus;
}

export enum EReservationStatus {
    ACCEPTER = 'ACCEPTER',
    DECLINED = 'DECLINED',
    INTERESTED = 'INTERESTED',
    PENDING = 'PENDING'
}