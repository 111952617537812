import { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../style/colorStack';

const Wrapper = styled.div`
    padding: 1rem;
    text-align: center;
    width: 100%;
    color: ${colorStack.blue};
`;

interface IProps {
    children: React.ReactNode;
}

export const NoData: FC<IProps> = props => {
    return (
        <Wrapper>
            {props.children}
        </Wrapper>
    );
};