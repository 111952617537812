import { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../style/colorStack';

const Wrapper = styled.div`
    color: ${colorStack.red};
    font-size: 0.8rem;
    margin-top: .2rem;
`;

interface IProps {
    children: React.ReactNode;
}

export const ErrorInfo: FC<IProps> = props => {
    return (
        <Wrapper>
            {props.children}
        </Wrapper>
    );
};