import { faCalendarDays, faCalendarPlus, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import { searchAllEvents } from '../../actions/eventActions';
import { IEvent } from '../../entities/IEvent';
import { colorStack } from '../../style/colorStack';
import { ActionMenu } from '../common/ActionMenu';
import { MultilineTextWithEllipsis } from '../common/MultilineTextWithEllipsis';
import { PageTitle } from '../common/PageTitle';
import { Button } from '../common/Buttons/Button';
import { TextComponent } from '../common/Inputs/TextComponent';
import { media } from '../../style/breakpoints';

const Wrapper = styled.div`
    position: relative;
`;

const SingleArena = styled(Link)`
    border: 1px solid ${colorStack.darkGrey2};
    border-radius: 8px;
    width: 100%;
    padding: 1rem;
    margin-bottom: 2rem;
    background: ${colorStack.white};
    box-shadow: 0px 4px 12px rgba(0,0,0, .16);
    transition: all .3s;
    cursor: pointer;
    &:hover {
        background: ${colorStack.darkGrey2};
    }
    ${media.desktop`
        width: 48%;
    `}
`;

const Top = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;
    img {
        width: 100%;
        font-size: 1.9rem;
        margin-bottom: 1rem;
        color: ${colorStack.darkGrey};
    }
    span {
        color: ${colorStack.blue};
        font-weight: 500;
        font-size: 1.2rem;
    }
`;

const Address = styled.div`
    color: ${colorStack.darkGrey};
    margin-bottom: .5rem;
    svg {
        margin-right: .5rem;
    }
`;

const ArenasList = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`;

const Buttons = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1rem;
    justify-content: space-between;
`;

const SearchBox = styled.div`
    display: flex;
    justify-content: space-between;
    > div {
        width: 48%;
    }
`;

export const AllEvents: FC = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const [events, setEvents] = useState<IEvent[] | undefined>(undefined);
    const [nameSearch, setNameSearch] = useState<string>('');
    const [citySearch, setCitySearch] = useState<string>('');

    useEffect(() => {
        dispatch<any>(searchAllEvents()).then((res: IEvent[]) => setEvents(res));
    }, []);

    const changeNameSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setNameSearch(text);
    };

    const changeCitySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setCitySearch(text);
    };

    const addNewEvent = () => {
        history('/edit-event');
    };

    return (
        <Wrapper>
            <ActionMenu>
                <FontAwesomeIcon icon={faCalendarPlus} onClick={addNewEvent} />
            </ActionMenu>
            <PageTitle h1="Eventy" h2="Lista wszystkich eventów" />
            <SearchBox>
                <TextComponent value={nameSearch} onChange={changeNameSearch} placeholder="Szukaj po nazwie" />
                <TextComponent value={citySearch} onChange={changeCitySearch} placeholder="Szukaj po mieście" />
            </SearchBox>
            <ArenasList>
                {events?.map(event => (
                    <SingleArena to={`/event/${event.id}`}>
                        <Top>
                            <img src={event.pictureUrl} alt="Logo eventu" />
                            <MultilineTextWithEllipsis numberOfLines={1}>
                                <span>{event.name}</span>
                            </MultilineTextWithEllipsis>
                        </Top>
                        <Address>
                            <MultilineTextWithEllipsis numberOfLines={1}>
                                <FontAwesomeIcon icon={faLocationDot} />{event.address?.city}, {event.address?.street} {event.address?.number}
                            </MultilineTextWithEllipsis>
                        </Address>
                        <Address>
                            <MultilineTextWithEllipsis numberOfLines={1}>
                                <FontAwesomeIcon icon={faPhone} />{event.address?.phone}
                            </MultilineTextWithEllipsis>
                        </Address>
                        <Address>
                            <FontAwesomeIcon icon={faCalendarDays} />
                            <span>{moment(event.startDate).format('LL, h:mm')} - {moment(event.endDate).format('LL, h:mm')}</span>
                        </Address>
                        <Buttons>
                            <Button onClick={() => {/** */ }}>Wezmę udział</Button>
                            <Button onClick={() => {/** */ }}>Zainteresowany</Button>
                        </Buttons>
                    </SingleArena>
                ))}
            </ArenasList>
        </Wrapper>
    );
};