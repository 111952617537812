import { FC, useState } from 'react';
import styled from 'styled-components';

import { isValidEmail } from '../../tools/emailChecker';
import { Button } from '../common/Buttons/Button';
import { TextComponent } from '../common/Inputs/TextComponent';
import { PageTitle } from '../common/PageTitle';

const Wrapper = styled.div``;

export const GetNewPassword: FC = () => {
    const [email, setEmail] = useState<string>('');
    const [sended, setSended] = useState<boolean>(false);

    const changeEmailHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setEmail(text);
    };

    const sendHandler = () => {
        setSended(true);
    };

    return (
        <Wrapper>
            <PageTitle h1="Przypomnij hasło" h2="Wpisz email przypominj sobie hasło" />
            {sended ? (
                <p>Wysłaliśmy do Ciebie maila z linkiem do ustawienia nowego hasła. Jeśli nie widzisz maila w głównej skrzynce sprawdź również folder Spam.</p>
            ) : (
                <>
                    <TextComponent value={email} onChange={changeEmailHandler} label="Email" />
                    <Button onClick={sendHandler} disabled={!isValidEmail(email)}>Wyślij</Button>
                </>
            )}
        </Wrapper>
    );
};