export const colorStack = {
    black: '#000',
    white: '#fff',
    darkGrey: '#0f0b03',
    darkGrey2: 'rgb(15 11 3 / 10%)',
    green: '#1ab419',
    lightBrown: '#fde7d0',
    red: '#ff0000',
    grey: '#545454',
    blue: '#2196F3'
};