import React, { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../style/colorStack';
import { Label } from './Label';

const Wrapper = styled.div`
    width: 100%;
    textarea {
        width: 100%;
        resize: none;
        height: 106px;
        border-radius: 4px;
        box-Shadow: inset 0px 1px 6px rgba(0,0,0, .08);
        border: 2px solid ${colorStack.darkGrey2};
        transition: border .3s;
        font-size: 1.2rem;
        outline: none;
        padding: .5rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        color: ${colorStack.darkGrey};
        &:focus {
            border: 2px solid ${colorStack.blue};
        }
    }
`;

interface IProps {
    label: string;
    value: string;
    required?: boolean;
    onChange(e: React.ChangeEvent<HTMLTextAreaElement>): void;
}

export const TextAreaComponent: FC<IProps> = props => {
    return (
        <Wrapper>
            <Label required={props.required} label={props.label} />
            <textarea value={props.value} onChange={props.onChange}/>
        </Wrapper>
    );
};