import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

import { IArena } from '../../entities/IArena';
import { getSingleArena } from '../../actions/arenasAction';
import { SimpleBox } from '../common/SimpleBox';
import { SingleArenaBox } from '../common/SingleArenaBox';
import { ActionMenu } from '../common/ActionMenu';
import { PageTitle } from '../common/PageTitle';

const Wrapper = styled.div`
    position: relative;
`;

export const AdminArena: FC = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [arena, setArena] = useState<IArena | undefined>(undefined);

    useEffect(() => {
        if (id) {
            dispatch<any>(getSingleArena(id)).then((res: IArena) => setArena(res));
        }
    }, [id]);

    const editCurrentArena = () => {
        history(`/edit-arena/${id}`);
    };

    return (
        <Wrapper>
            <PageTitle h1="Twoja arena" h2="Statystyki, eventy, kalendarz w jednym miejscu" />
            {arena &&
                <>
                    <ActionMenu>
                        <FontAwesomeIcon icon={faPen} onClick={editCurrentArena} />
                    </ActionMenu>
                    <SingleArenaBox arena={arena} />
                    <SimpleBox>
                        Tutaj będzie panel admina areny
                        <br />
                        Statystyki, eventy, kalendarz wydarzeń na arenie itp
                    </SimpleBox>
                </>
            }
        </Wrapper>
    );
};