import { IUserProfile } from './IUserProfile';

export interface IPhoto {
    photoUrl: string;
    name: string;
    id: string;
    description: string;
    place: {
        lat: number;
        long: number;
    };
    members: IUserProfile[];
    emotions: IEmotions;
}

export enum IEmotions {
    CHEERFUL = 'Wesoły',
    SAD = 'Smutny',
    TWISTED = 'Zakręcony',
    ANGRY = 'Zły'
}

export interface IAddress {
    countryISO: string;
    country: string;
    state: string;
    city: string;
    zipCode: string;
    street: string;
    number: string;
    phone: string;
    id: string;
    lat?: number;
    lng?: number;
    isArena?: boolean;
}

export interface IAddressObject {
    street_number?: string;
    route?: string;
    neighborhood?: string;
    locality?: string;
    administrative_area_level_1?: string;
    country?: string;
    postal_code?: string;
}

export enum IIcoMedal {
    ORG = 'organizator',
    HELP = 'pomoc'
}

export enum ISeason {
    SPRING = 'Wiosna',
    SUMMER = 'Lato',
    AUTUMN = 'Jesień',
    WINTER = 'Zima'
}

export enum ISocial {
    FACEBOOK = 'Facebook',
    INSTAGRAM = 'Instagram',
    TWITTER = 'Twitter',
    WEBSITE = 'Website',
    YOUTUBE = 'Youtube',
    PHONE = 'Phone'
}

export const CBallsSize = [
    '0.12g',
    '0.20g',
    '0.23g',
    '0.25g',
    '0.28g',
    '0.30g',
    '0.32g',
    '0.33g',
    '0.35g',
    '0.36g',
    '0.40g',
    '0.43g',
    '0.45g',
    '0.46g',
    '0.48g',
    '0.49g',
    '0.50g',
    '0.58g'
];

export const CCompaniesList = [
    'A&K',
    'ACM',
    'ACTION ARMY',
    'AGM',
    'AMOEBA',
    'APS',
    'ARES',
    'RMY',
    'ASG',
    'AVATAR grenade',
    'AY',
    'Airsoft Innovations',
    'Airsoft Pioneer',
    'Armorer Works',
    'Arrow Arms',
    'Arrow Dynamic',
    'Ascend Airsoft',
    'B&W',
    'BO Manufacture',
    'BOLT Airsoft',
    'Bear Paw Production',
    'CAA AIRSOFT DIVISION',
    'Własny CUSTOM',
    'CSI',
    'CYMA',
    'Classic Army',
    'CyberGun',
    'DE',
    'DRAGON',
    'Dave`s Custom Airsoft',
    'Dominator International',
    'Double Bell',
    'Duel Code',
    'Dytac',
    'E&L',
    'EMG',
    'EVOLUTION AIRSOFT',
    'Elite Force',
    'ForceCore Armament',
    'G&G',
    'G&P',
    'GF Custom Division',
    'GFC Guns',
    'GHK',
    'Galaxy',
    'Golden Eagle',
    'HFC',
    'Haw San',
    'ICS',
    'JG/GE',
    'JG',
    'KJ WORKS',
    'KRYTAC',
    'KWA Airsoft',
    'KWC',
    'Kimera',
    'King Arms',
    'LCT',
    'LayLax',
    'MadBull',
    'Maple Leaf',
    'Marushin',
    'Modify',
    'NPO AEG',
    'Novritsch',
    'Nuprol',
    'Oberland Arms',
    'PPS Airsoft',
    'PTS',
    'Poseidon',
    'ROSSI',
    'RWA',
    'Raptor',
    'Raven',
    'Real Sword',
    'RedWolf Custom',
    'S&T ARMAMENT',
    'S-Thunder',
    'SHS',
    'SILVERBACK AIRSOFT',
    'SPARTAC',
    'SRC',
    'SY',
    'Secutor',
    'Sig Sauer',
    'Snow Wolf',
    'Specna Arms',
    'TANAKA',
    'Tactical Game Innovation',
    'Tectonic Innovations',
    'Tokyo Marui',
    'Umarex',
    'Umbrella Armory',
    'VFC',
    'Valken',
    'Vorsk',
    'WELL',
    'WE',
    'WinGun',
    'ZOXNA Airsoft'
];

export interface IDefaultData {
    playerLimit: number | undefined;
    legal: boolean;
    chrono: boolean; // czy wymagane jest każdorazowe chronowanie
    parking: boolean;
    onlyBioBalls: boolean;
    ageLimit: boolean;
    ticketPrice: ISingleTicket[];
}

export interface ISingleTicket {
    id: string;
    name: string;
    price: string;
    description: string;
}

export interface ISecurityData {
    token: string;
    userId: string;
    email: string;
    authorities: string[];
}