import { faPlus } from '@fortawesome/free-solid-svg-icons';
import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../style/colorStack';
import { Button } from '../Buttons/Button';
import { Label } from '../Inputs/Label';
import { Popup } from '../Popup/Popup';
import { CloseButton } from '../Buttons/CloseButton';

const Wrapper = styled.div`
    margin-bottom: 1rem;
`;

const Content = styled.div<{noMargin: boolean}>`
    ${props => !props.noMargin && css`
        margin-top: 2rem;
    `}
`;

const Inner = styled.div`
    border: 1px solid ${colorStack.darkGrey2};
    border-radius: 8px;
    padding: 1rem 2rem 1rem 1rem;
`;

const SingleObj = styled.div`
    position: relative;
    padding: 0 1rem 0 0;
    margin-bottom: 1rem;
`;

interface IProps<T> {
    label?: string;
    required?: boolean;
    value: T[];
    children: React.ReactNode;
    singleElem: FC<{ data: T }>;
    onlyView?: boolean;
    saveHandler?(): void;
}

export const MultiObjectArea = <T extends object = {}>(props: IProps<T>) => {
    const [showPopup, setShowPopup] = useState<boolean>(false);

    const SingleElem: any = props.singleElem;

    const addSection = () => {
        setShowPopup(state => !state);
    };

    const removeElem = () => {
        //
    };

    const saveHandler = () => {
        props.saveHandler && props.saveHandler();
        setShowPopup(false);
    };

    return (
        <Wrapper>
            {props.label && <Label required={props.required} label={props.label} />}
            <Inner>
                {!props.onlyView &&
                    <>
                        <Button icon={faPlus} onClick={addSection}>Dodaj</Button>
                        <Popup showPopup={showPopup} changeVisibility={addSection} title="Nowy element" saveHandler={saveHandler}>
                            {props.children}
                        </Popup>
                    </>
                }
                <Content noMargin={!!props.onlyView}>
                    {props.value?.map((elem, index) => (
                        <SingleObj key={index}>
                            {!props.onlyView &&
                                <CloseButton onClick={removeElem}/>
                            }
                            <SingleElem data={elem} />
                        </SingleObj>
                    ))}
                </Content>
            </Inner>
        </Wrapper>
    );
};