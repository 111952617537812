import { FC } from 'react';
import styled from 'styled-components';

import { PageTitle } from '../common/PageTitle';

const Wrapper = styled.div``;

const Content = styled.div`
    ul {
        list-style: circle;
    }
    ol {
        list-style: auto;
        ul {
            margin: 1rem 0;
            padding-left: 2rem;
        }
    }
    li {
        margin-bottom: .5rem;
    }
    p {
        font-weight: 700;
        margin: 2rem;
    }
`;

export const Privacy: FC = () => {
    return (
        <Wrapper>
            <PageTitle h1="Polityka prywatności" h2="Sprawdź jak chronimy Twoje dane" />
            <Content>
                <ol>
                    <li>
                        Dla Właściciela  niniejszej strony internetowej, ochrona danych osobowych Użytkowników jest sprawą najwyższej wagi. Dokłada on ogrom starań,
                        aby Użytkownicy czuli się bezpiecznie, powierzając swoje dane osobowe w trakcie korzystania ze strony internetowej.
                    </li>
                    <li>
                        Użytkownik to osoba fizyczna, osoba prawna albo jednostka organizacyjna, nieposiadająca osobowości prawnej, której ustawa przyznaje zdolność prawną,
                        korzystająca z usług elektronicznych, dostępnych w ramach strony internetowej.
                    </li>
                    <li>
                        Niniejsza polityka prywatności wyjaśnia zasady i zakres przetwarzania danych osobowych Użytkownika, przysługujące mu prawa, jak też obowiązki administratora tych danych.
                    </li>
                    <li>
                        Administrator stosuje najnowocześniejsze środki techniczne i rozwiązania organizacyjne, zapewniające wysoki poziom ochrony przetwarzanych danych osobowych oraz zabezpieczenia przed dostępem osób nieupoważnionych.
                    </li>
                </ol>
                <p>Administrator danych osobowych</p>
                <ol>
                    <li>Administrator przetwarza dane osobowe Użytkownika w celu dostarczenia użytkownikowi najlepszych wyników działania aplikacji, w tym statystyk użytkownika.</li>
                    <li>
                        Oznacza to, że dane te są potrzebne w szczególności do
                        <ul>
                            <li>zarejestrowania się na stronie internetowej;</li>
                            <li>zawarcia umowy;</li>
                            <li>dokonania rozliczeń;</li>
                            <li>dostarczenia zamówionego przez Użytkownika towaru lub wykonanie usług;</li>
                            <li>korzystania przez Użytkownika z wszelkich uprawnień konsumenckich (np. odstąpienie od umowy, rękojmia).</li>
                            <li>usługi serwowania wyników wyszukiwania w oparciu o dane użytkownika</li>
                        </ul>
                    </li>
                    <li>
                        Użytkownik może również wyrazić zgodę na otrzymywanie informacji o nowościach i promocjach, co spowoduje, że administrator będzie również przetwarzać dane osobowe, w celu przesyłania Użytkownikowi informacji handlowych, dotyczących m.in. nowych produktów lub usług, promocji czy wyprzedaży.
                    </li>
                    <li>
                        Dane osobowe są również przetwarzane w ramach wypełnienia prawnych obowiązków, ciążących na administratorze danych oraz realizacji zadań, w interesie publicznym m.in. do wykonywania zadań, związanych z bezpieczeństwem i obronnością lub przechowywaniem dokumentacji podatkowej.
                    </li>
                </ol>
                <p>Prawa przysługujące użytkownikowi</p>
                <ol>
                    <li>Użytkownik może w każdej chwili zażądać od administratora informacji o zakresie przetwarzania danych osobowych.</li>
                    <li>Użytkownik może w każdej chwili zażądać poprawienia bądź sprostowania swoich danych osobowych. Użytkownik może to również zrobić samodzielnie, po zalogowaniu się na swoje konto.</li>
                    <li>Użytkownik może w każdej chwili wycofać swoją zgodę na przetwarzanie jego danych osobowych, bez podawania przyczyny. Żądanie nieprzetwarzania danych może dotyczyć wskazanego przez Użytkownika konkretnego celu przetwarzania np. wycofanie zgody na otrzymywanie informacji handlowych bądź dotyczyć wszystkich celów przetwarzania danych. Wycofanie zgody co do wszystkich celów przetwarzania spowoduje, że konto Użytkownika zostanie usunięte ze strony internetowej, wraz ze wszystkimi wcześniej przetwarzanymi przez administratora danymi osobowymi Użytkownika. Wycofanie zgody nie wpłynie na już dokonane czynności.</li>
                    <li>Użytkownik może w każdej chwili żądać, bez podawania przyczyny, aby administrator usunął Jego dane. Żądanie usunięcia danych nie wpłynie na dotychczas dokonane czynności. Usunięcie danych oznacza jednoczesne usunięcie konta Użytkownika, wraz ze wszystkimi zapisanymi i przetwarzanymi do tej pory przez administratora danymi osobowymi.</li>
                    <li>Użytkownik może w każdej chwili wyrazić sprzeciw przeciwko przetwarzaniu danych osobowych, zarówno w zakresie wszystkich przetwarzanych przez administratora danych osobowych Użytkownika, jak również jedynie w ograniczonym zakresie np. co do przetwarzania danych w konkretnie wskazanym celu. Sprzeciw nie wpłynie na dotychczas dokonane czynności. Wniesienie sprzeciwu spowoduje usunięcie konta Użytkownika, wraz ze wszystkimi zapisanymi i przetwarzanymi do tej pory, przez administratora, danymi osobowymi.</li>
                    <li>Użytkownik może zażądać ograniczenia przetwarzania danych osobowych, czy to przez określony czas, czy też bez ograniczenia czasowego, ale w określonym zakresie, co administrator będzie obowiązany spełnić. Żądanie to nie wpłynie na dotychczas dokonane czynności.</li>
                    <li>Użytkownik może zażądać, aby administrator przekazał innemu podmiotowi, przetwarzane dane osobowe Użytkownika. Powinien w tym celu napisać prośbę do administratora, wskazując, jakiemu podmiotowi (nazwa, adres) należy przekazać dane osobowe Użytkownika oraz jakie konkretnie dane Użytkownik życzy sobie, żeby administrator przekazał. Po potwierdzeniu przez Użytkownika swojego życzenia, administrator przekaże, w formie elektronicznej, wskazanemu podmiotowi, dane osobowe Użytkownika. Potwierdzenie przez Użytkownika żądania jest niezbędne z uwagi na bezpieczeństwo danych osobowych Użytkownika oraz uzyskanie pewności, że żądanie pochodzi od osoby uprawnionej.</li>
                    <li>Administrator informuje Użytkownika o podjętych działaniach, przed upływem miesiąca od otrzymania jednego z żądań wymienionych w poprzednich punktach.</li>
                </ol>
                <p>Powierzanie przetwarzania danych innym podmiotom</p>
                <ol>
                    <li>Administrator może powierzać przetwarzanie danych osobowych podmiotom współpracującym z administratorem, w zakresie niezbędnym dla realizacji transakcji np. w celu przygotowania zamówionego towaru oraz dostarczania przesyłek lub przekazywania informacji handlowych, pochodzących od administratora (ostatnie dotyczy Użytkowników, którzy wyrazili zgodę na otrzymywanie informacji handlowych).</li>
                    <li>Poza celami, wskazanymi w niniejszej Polityce Prywatności, dane osobowe Użytkowników, nie będą w żaden sposób udostępniane osobom trzecim, ani przekazywane innym podmiotom, w celu przesyłania materiałów marketingowych tych osób trzecich.</li>
                    <li>Dane osobowe Użytkowników strony internetowej nie są przekazywane poza obszar Unii Europejskiej.</li>
                    <li>Niniejsza Polityka Prywatności jest zgodna z przepisami wynikającymi z art. 13 ust. 1 i ust. 2 rozporządzenia RODO.</li>
                </ol>
            </Content>
        </Wrapper>
    );
};