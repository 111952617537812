import { FC } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Contact } from './Contact';
import { Home } from './Home';
import { Register } from './Register';
import { Soon } from './Soon';
import { Login } from './Login';
import { Profile } from './Profile';
import { AllArenas } from './AllArenas';
import { SingleArena } from './SingleArena';
import { AllEvents } from './AllEvents';
import { SingleEvent } from './SingleEvent';
import { EditArena } from './EditArena';
import { EditEvent } from './EditEvent';
import { ChangePassword } from './ChangePassword';
import { GetNewPassword } from './GetNewPassword';
import { IUserProfileReducer } from '../../reducers/profileReducer';
import { IState } from '../../reducers';
import { AdminEvent } from './AdminEvent';
import { AdminArena } from './AdminArena';
import { AdminGroup } from './AdminGroup';
import { Cookies } from './Cookies';
import { Privacy } from './Privacy';
import { MyEvents } from './MyEvents';
import { MyFriends } from './MyFriends';
import { MyArenas } from './MyArenas';
import { MyGroups } from './MyGroups';

const ProtectedRoute = (data: any) => {
    if (!data.user) {
        return <Navigate to={data.redirectPath} replace />;
    }

    return <Outlet />;
};

export const Navigation: FC = () => {
    const { userProfile } = useSelector<IState, IUserProfileReducer>(state => state.profile);

    return (
        <Routes>
            <Route index element={<Home />} />
            <Route element={<ProtectedRoute user={userProfile} redirectPath="/login" />}>
                <Route path="profile/:id" element={<Profile />} />
                <Route path="profile/:id/events" element={<MyEvents />} />
                <Route path="profile/:id/friends" element={<MyFriends />} />
                <Route path="profile/:id/stats" element={<Soon />} />
                <Route path="profile/:id/services" element={<Soon />} />
                <Route path="profile/:id/arenas" element={<MyArenas />} />
                <Route path="profile/:id/groups" element={<MyGroups />} />
                <Route path="event/:id" element={<SingleEvent />} />
                <Route path="event/:id/admin" element={<AdminEvent />} />
                <Route path="group/:id" element={<Soon />} />
                <Route path="group/:id/admin" element={<AdminGroup />} />
                <Route path="arena/:id" element={<SingleArena />} />
                <Route path="arena/:id/admin" element={<AdminArena />} />
                <Route path="edit-arena/:id?" element={<EditArena />} />
                <Route path="edit-event/:id?" element={<EditEvent />} />
                <Route path="edit-team/:id?" element={<Soon />} />
                <Route path="bazarek" element={<Soon />} />
                <Route path="services" element={<Soon />} />
                <Route path="service/:id" element={<Soon />} />
                <Route path="edit-service/:id?" element={<Soon />} />
                <Route path="change-password" element={<ChangePassword />} />
            </Route>
            <Route path="get-password" element={<GetNewPassword />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="cookies" element={<Cookies />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="about" element={<Contact />} />
            <Route path="arenas" element={<AllArenas />} />
            <Route path="groups" element={<Soon />} />
            <Route path="events" element={<AllEvents />} />
        </Routes>
    );
};