import { FC } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../style/colorStack';
import { Label } from './Label';
import { ErrorInfo } from '../ErrorInfo';

const Wrapper = styled.div<{ err: boolean }>`
    width: 100%;
    margin-bottom: 1rem;
    input {
        box-Shadow: inset 0px 1px 6px rgba(0,0,0, .08);
        width: 100%;
        border-radius: 4px;
        border: 2px solid ${colorStack.darkGrey2};
        transition: border .3s;
        font-size: 1.2rem;
        height: 35px;
        padding: .5rem;
        font-family: 'Roboto', sans-serif;
        outline: none;
        font-weight: 400;
        color: ${colorStack.darkGrey};
        &:focus {
            border: 2px solid ${colorStack.blue};
        }
        ${props => props.err && css`
            border: 2px solid ${colorStack.red};
        `}
    }
    ${props => props.err && css`
        margin-bottom: 0;
    `}
`;

interface IProps {
    value?: string;
    label?: string;
    placeholder?: string;
    required?: boolean;
    errorMsg?: string;
    type?: React.HTMLInputTypeAttribute;
    onChange(e: React.ChangeEvent<HTMLInputElement>): void;
    onBlur?(): void;
}

export const TextComponent: FC<IProps> = props => {

    return (
        <Wrapper err={!!props.errorMsg}>
            {props.label && <Label required={props.required} label={props.label} />}
            <input placeholder={props.placeholder} type={props.type || 'text'} value={props.value} onChange={props.onChange} onBlur={props.onBlur} />
            {props.errorMsg &&
                <ErrorInfo>{props.errorMsg}</ErrorInfo>
            }
        </Wrapper>
    );
};