import { FC } from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../style/breakpoints';

const Wrapper = styled.div<IProps>`
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    ${media.desktop`
        flex-direction: row;
    `}
    > div {
        width: 100%;
        ${props => props.numberOfElements > 1 && css`
            width: 100%;
            ${media.desktop`
                width: ${`${(100 / props.numberOfElements) - 1}%`};
            `}
        `}
    }
    &:last-child {
        border: none;
        margin: 0;
        padding: 0;
    }
`;

interface IProps {
    numberOfElements: number;
    children: React.ReactNode;
}

export const Row: FC<IProps> = props => {
    return (
        <Wrapper numberOfElements={props.numberOfElements}>
            {props.children}
        </Wrapper>
    );
};