import { IPhoto } from './IGlobal';

export interface IReplica {
    name: string;
    picture: string;
    balls?: string;
    company: string;
    id: string;
    model: string;
    range?: string;
    power?: IReplicaPower;
    gearbox?: string;
    drive?: EReplicaDrive;
    type: EReplicaType;
    magazineCapacity?: string;
    weight: string;
    description: string;
    rating: number;
    photos: IPhoto[];
}

export interface IReplicaPower {
    fps: string;
    jouls: string;
}

export interface IReplicaLimits {
    power: IReplicaPower;
    single: boolean;
}

export enum EReplicaDrive {
    spring = 'Sprężyna',
    electric = 'Elektryczna',
    sniper = 'Czterotakt'
}

export enum EReplicaType {
    none = '',
    sniper = 'Sniperka',
    dmr = 'DMR',
    support = 'Replika wsparcia',
    cqb = 'CQB',
    sideReplica = 'Boczniak',
    grenades = 'Granaty'
}

export enum IGearType {
    gloves = 'Rękawice',
    kneePads = 'Nakolanniki',
    shoes = 'Obuwie',
    uniform = 'Mundur',
    eyes = 'Ochrona oczu',
    connect = 'Łączność',
    other = 'Oporządzenie'
}