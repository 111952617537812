import { IArena } from '../../entities/IArena';

export const emptyArena: IArena = {
    id: '',
    name: '',
    headMaster: undefined,
    shortDescription: '',
    email: '',
    address: undefined,
    pictureUrl: '',
    startDate: new Date(),
    stableMembers: [],
    stableTeams: [],
    blockedMembers: [],
    social: [],
    description: '',
    rating: 0,
    availableReplicasForRental: [],
    photos: [],
    comments: [],
    defaultData: {
        playerLimit: undefined,
        legal: false,
        chrono: false,
        parking: false,
        onlyBioBalls: false,
        ageLimit: false,
        ticketPrice: []
    }
};