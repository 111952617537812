import { FC } from 'react';
import styled from 'styled-components';

import { PageTitle } from '../common/PageTitle';

const Wrapper = styled.div``;

const Content = styled.div`
    ul {
        list-style: circle;
    }
    ol {
        list-style: auto;
        ul {
            margin: 1rem 0;
            padding-left: 2rem;
        }
    }
    li {
        margin-bottom: .5rem;
    }
`;

export const Cookies: FC = () => {
    return (
        <Wrapper>
            <PageTitle h1="Polityka Cookies" h2="Poznaj jakie i w jakim celu używamy ciasteczka" />
            <Content>
                <ol>
                    <li>
                        Strona internetowa używa plików cookies (ciasteczka) lub podobną technologię (dalej łącznie nazywane: "<strong>cookies</strong>") do zbierania informacji o
                        dostępie Użytkownika do strony internetowej (np. za pomocą komputera lub smartfonu) oraz jego preferencjach. Są one wykorzystywane m.in. w
                        celach reklamowych i statystycznych oraz w celu dostosowania strony internetowej do indywidualnych potrzeb Użytkownika.
                    </li>
                    <li>
                        Pliki cookies to fragmenty informacji, które zawierają unikalny kod referencyjny, który strona internetowa przesyła na urządzenie Użytkownika, w
                        celu przechowywania, a czasem śledzenia informacji, dotyczących używanego urządzenia. Zwykle nie pozwalają one zidentyfikować osoby Użytkownika.
                        Ich głównym zadaniem jest lepsze dopasowanie strony internetowej do Użytkownika.
                    </li>
                    <li>
                        Niektóre z plików cookies, występujące na stronie internetowej, są dostępne tylko przez czas trwania danej sesji internetowej i wygasają po zamknięciu
                        przeglądarki. Inne pliki cookies służą do zapamiętywania Użytkownika, który po powrocie na stronę internetową, jest na niej rozpoznawany.
                        Są one wówczas zachowywane przed dłuższy czas.
                    </li>
                    <li>
                        Pliki cookies używane na niniejszej stronie internetowej to:
                        <ul>
                            <li>PlayAirAuth - do momentu wylogowania</li>
                            <li>PlayAirCookie - 1 rok</li>
                            <li>_GRECAPTCHA - 1 rok</li>
                            <li>_ga - 6 miesięcy</li>
                            <li>_ga_XF6G85108L - 6 miesięcy</li>
                        </ul>
                    </li>
                    <li>
                        Wszystkie pliki cookies, występujące na stronie internetowej, są ustalane przez administratora.
                    </li>
                    <li>
                        Wszystkie pliki cookies, używane przez niniejszą stronę internetową, są zgodne z obowiązującym prawem Unii Europejskiej.
                    </li>
                    <li>
                        Większość Użytkowników i niektórych przeglądarek mobilnych automatycznie akceptuje pliki cookies.
                        Jeżeli Użytkownik nie zmieni ustawień, pliki cookies zostaną zapisane w pamięci urządzenia.
                    </li>
                    <li>
                        Większość Użytkowników i niektórych przeglądarek mobilnych automatycznie akceptuje pliki cookies. Jeżeli Użytkownik nie zmieni ustawień,
                        pliki cookies zostaną zapisane w pamięci urządzenia.
                    </li>
                    <li>
                        Użytkownik może zmienić preferencje, dotyczące akceptacji plików cookies lub zmienić przeglądarkę, aby móc otrzymać za każdym razem stosowne
                        powiadomienie, gdy funkcja cookies jest ustawiona. Aby zmienić ustawienia akceptacji cookies, należy dostosować ustawienia w przeglądarce.
                    </li>
                    <li>
                        Warto pamiętać, że blokowanie lub usuwanie plików cookies może uniemożliwić pełne korzystanie ze strony internetowej.
                    </li>
                    <li>
                        Pliki cookies będą wykorzystywane do niezbędnego zarządzania sesją, w tym:
                        <ul>
                            <li>
                                Tworzenia specjalnej sesji logowania dla Użytkownika strony internetowej, aby strona zapamiętała, że Użytkownik jest zalogowany,
                                a jego żądania były dostarczane w sposób skuteczny, bezpieczny i spójny;
                            </li>
                            <li>
                                Rozpoznawania Użytkownika, który już wcześniej odwiedził stronę internetową, co pozwala na identyfikację liczby unikalnych użytkowników,
                                którzy skorzystali z serwisu i pozwala upewnić się co do wystarczającej pojemności serwisu dla liczby nowych użytkowników;
                            </li>
                            <li>Rozpoznawania, czy osoba odwiedzająca stronę internetową jest zarejestrowana na stronie internetowej;</li>
                            <li>
                                Rejestrowanie informacji z urządzenia Użytkownika, w tym: pliki cookies, adres IP i informacje o używanej przeglądarce, w celu możliwości
                                diagnozowania problemów, administrowania i śledzenia Użytkowania witryny;
                            </li>
                            <li>Dostosowywania elementów układu szaty graficznej lub zawartości strony internetowej;</li>
                            <li>
                                Zbierania informacji statystycznych o tym, jak Użytkownik korzysta ze strony, w celu możliwości ulepszania witryny i stwierdzenia, które zakresy
                                strony internetowej są najbardziej popularne dla Użytkowników.
                            </li>
                        </ul>
                    </li>
                </ol>
            </Content>
        </Wrapper>
    );
};