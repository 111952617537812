import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

import { SingleEventBox } from '../common/SingleEventBox';
import { Label } from '../common/Inputs/Label';
import { Checkbox } from '../common/Inputs/Checkbox';
import { Avatar } from '../common/Avatar';
import { IEvent } from '../../entities/IEvent';
import { getSingleEvent } from '../../actions/eventActions';
import { SimpleBox } from '../common/SimpleBox';
import { ActionMenu } from '../common/ActionMenu';
import { PageTitle } from '../common/PageTitle';

const Wrapper = styled.div`
    position: relative;
`;

const List = styled.div`
    margin-top: 2rem;
    > div {
        display: flex;
        align-items: center;
    }
`;

export const AdminEvent: FC = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [event, setEvent] = useState<IEvent | undefined>(undefined);

    useEffect(() => {
        if (id) {
            dispatch<any>(getSingleEvent(id)).then((res: IEvent) => setEvent(res));
        }
    }, [id]);

    const editCurrentEvent = () => {
        history(`/edit-event/${id}`);
    };

    return (
        <Wrapper>
            <PageTitle h1="Twoje wydarzenie" h2="Statystyki, użytkownicy" />
            {event &&
                <>
                    <ActionMenu>
                        <FontAwesomeIcon icon={faPen} onClick={editCurrentEvent} />
                    </ActionMenu>
                    <SingleEventBox event={event} />
                    <SimpleBox>
                        <Label label="Osoby potwierdzone" />
                        <List>
                            <div>
                                <Checkbox label=" " field="" checked onChange={() => {/** */ }} />
                                <Avatar
                                    id=""
                                    pictureUrl="https://asgkrakowarena.pl/wp-content/uploads/2021/06/logo.png"
                                    name="Jaś Kowalski"
                                    data={undefined}
                                />
                            </div>
                        </List>
                    </SimpleBox>
                    <SimpleBox>
                        <Label label="Osoby zapisane niepotwierdzone" />
                    </SimpleBox>
                    <SimpleBox>
                        <Label label="Osoby odrzucone" />
                    </SimpleBox>
                    <SimpleBox>
                        <Label label="Statystyki - przyszłość" />
                    </SimpleBox>
                </>
            }
        </Wrapper>
    );
};