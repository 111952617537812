import { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import useDropdown from 'react-dropdown-hook';
import moment from 'moment';
import { Option } from 'react-dropdown';

import { Label } from './Label';
import { colorStack } from '../../../style/colorStack';
import { DropDown } from '../DropDown';

const Wrapper = styled.div<{fullView: boolean}>`
    width: 100%;
    position: relative;
    margin-bottom: 1rem;
    input {
        width: 100%;
        border-radius: 4px;
        border: 2px solid ${colorStack.darkGrey2};
        transition: border .3s;
        font-size: 1.2rem;
        height: 35px;
        padding: .5rem;
        font-family: 'Roboto', sans-serif;
        outline: none;
        font-weight: 400;
        width: calc(100% - 160px - 1rem);
        color: ${colorStack.darkGrey};
        ${props => props.fullView && css`
            width: 100%;
        `}
        &:focus {
            border: 2px solid ${colorStack.blue};
        }
    }
    .calendar-wrapper {
        position: absolute;
        top: 60px;
        z-index: 8;
        border-radius: 8px;
        left: 10px;
        border: 1px solid ${colorStack.darkGrey2};
    }
    .time {
        width: 80px;
        margin-left: .5rem;
    }
`;

const Content = styled.div`
    display: flex;
    > div {
        margin: 0;
    }
`;

const hourList = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'];
const minutesList = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];

interface IProps {
    label: string;
    required?: boolean;
    onlyDate?: boolean;
    value: Date | undefined;
    onChange(date: Date): void;
}

export const DatePicker: FC<IProps> = props => {
    const [wrapperRef, dropdownOpen, toggleDropdown, closeDropdown] = useDropdown();
    const [date, setDate] = useState<Date>();

    const handleSelect = (value: Date) => {
        setDate(value);
        props.onChange(value);
        toggleDropdown();
    };

    const changeHours = (data: Option) => {
        const newDate = date;
        newDate?.setHours(Number(data.value));
        if (newDate) {
            setDate(new Date(newDate));
        }
    };

    const changeMinutes = (data: Option) => {
        const newDate = date;
        newDate?.setMinutes(Number(data.value));
        if (newDate) {
            setDate(new Date(newDate));
        }
    };

    return (
        <Wrapper ref={wrapperRef} fullView={props.onlyDate || false}>
            {props.label && <Label required={props.required} label={props.label} />}
            <Content>
                <input type="text" onFocus={toggleDropdown} value={date ? moment(date).format('l') : undefined} />
                {!props.onlyDate &&
                    <>
                        <DropDown
                            disabled={!date}
                            placeholder="godz."
                            options={hourList}
                            className="time"
                            onChange={changeHours}
                        />
                        <DropDown
                            disabled={!date}
                            placeholder="min."
                            options={minutesList}
                            className="time"
                            onChange={changeMinutes}
                        />
                    </>
                }
            </Content>
            {dropdownOpen &&
                <Calendar
                    className="calendar-wrapper"
                    date={date}
                    onChange={handleSelect}
                />
            }
        </Wrapper>
    );
};