import { FC } from 'react';
import styled from 'styled-components';

import { PageTitle } from '../common/PageTitle';

const Wrapper = styled.div``;

export const AdminGroup: FC = () => {
    return (
        <Wrapper>
            <PageTitle h1="Centrum dowodzenia" h2="Statystyki, użytkownicy" />
        </Wrapper>
    );
};