import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { SimpleBox } from '../common/SimpleBox';
import { NoData } from '../common/NoData';
import { Button } from '../common/Buttons/Button';

const Wrapper = styled.div``;

export const MyGroups: FC = () => {
    const history = useNavigate();

    const addNewTeam = () => {
        history('/edit-team');
    };

    return (
        <Wrapper>
            <SimpleBox>
                <NoData>Nie dołączyłeś jeszcze do żadnej grupy.</NoData>
                <Button onClick={addNewTeam}>Dodaj nową grupę</Button>
            </SimpleBox>
        </Wrapper>
    );
};