import { Dispatch } from 'redux';

import { IArena } from '../entities/IArena';
import { ISocial } from '../entities/IGlobal';
import { EReplicaDrive, EReplicaType } from '../entities/IReplica';
import { IState } from '../reducers';

const mockArena: IArena = {
    id: '1',
    name: 'ASG Kraków Arena',
    shortDescription: 'To jest krótki zajawkowy opis To jest krótki zajawkowy opis',
    headMaster: {
        firstName: 'Przemek',
        lastName: 'Cygan',
        id: '8a80857080ec078a0181396d79d00005',
        email: 'test@test',
        type: 0
    },
    email: 'test@test',
    address: {
        countryISO: 'PL',
        country: 'Polska',
        state: 'Małopolska',
        city: 'Kraków',
        zipCode: '30-889',
        street: 'Stefana Stolorza',
        number: '1111',
        phone: '666 666 666',
        id: '156'
    },
    pictureUrl: 'https://asgkrakowarena.pl/wp-content/uploads/2021/06/logo.png',
    startDate: new Date(),
    stableMembers: [],
    stableTeams: [],
    blockedMembers: [],
    social: [{
        type: ISocial.FACEBOOK,
        value: 'http://facebook'
    }, {
        type: ISocial.INSTAGRAM,
        value: 'http://facebook'
    }, {
        type: ISocial.TWITTER,
        value: 'http://facebook'
    }, {
        type: ISocial.WEBSITE,
        value: 'http://facebook'
    }, {
        type: ISocial.YOUTUBE,
        value: 'http://facebook'
    }, {
        type: ISocial.PHONE,
        value: '666 666 666'
    }],
    description: `<p><strong>Czołgiem!&nbsp;</strong></p><h1>Zapraszam na strzelanie wieczorne w doborowym towarzystwie!&nbsp;</h1><p>Limit osób: 24&nbsp;</p><blockquote>Wydarzenie odbywa się przy minimalnej ilości 8 osób</blockquote><p>Na listę zapisujemy się do godziny 15:00 w dzień strzelania</p><p>OBOWIĄZKOWE KULKI BIO!</p><p><strong>Limit mocy</strong> 1.2 Joul'a</p><p>Semi Only</p><p>Wpisowe: 30 zł</p><p><s>(Osoby posiadające karnet zniżka 50%)</s></p><p>Latarki i Tracery mile widziane</p><p>Uwaga: Ostrzeżenie dla osób z epilepsją</p><p>(jeżeli masz skłonności epileptyczne to nie jest wydarzenie dla Ciebie)</p><p><strong>Zbiórka</strong>: ~18:00</p><p>Start: 18:30</p><p>(można dołączyć później tylko proszę o informacje)</p>`,
    rating: 5,
    availableReplicasForRental: [{
        name: 'Nazwa repliki',
        picture: 'https://cdn.militaria.pl/media/catalog/product/cache/e452159aead1684753d5e9341f2edeb6/2/6/265719_Pistolet-maszynowy-GBB-Heckler-Koch-MP5-A5-V2.jpg',
        balls: '0,25',
        company: 'Cyma',
        id: '1',
        model: 'MP5',
        range: '50m',
        power: {
            fps: 'wszystkie',
            jouls: '1.2'
        },
        gearbox: 'Jakiś gearBox',
        drive: EReplicaDrive.electric,
        type: EReplicaType.cqb,
        magazineCapacity: '120',
        weight: '3,5k',
        description: 'Opis repliki lorem ipsum samet doloreOpis repliki lorem ipsum samet dolore Opis repliki lorem ipsum samet dolore Opis repliki lorem ipsum samet dolore Opis repliki lorem ipsum samet dolore',
        rating: 0,
        photos: []
    }],
    photos: [],
    comments: [],
    defaultData: {
        playerLimit: 40,
        legal: true,
        chrono: true,
        parking: true,
        onlyBioBalls: true,
        ageLimit: true,
        ticketPrice: [{
            id: '1',
            name: 'Nazwa biletu',
            price: '30',
            description: 'opis biletu'
        }, {
            id: '2',
            name: 'Nazwa biletu',
            price: '50',
            description: 'opis biletu'
        }, {
            id: '3',
            name: 'Nazwa biletu',
            price: '60',
            description: 'opis biletu'
        }, {
            id: '4',
            name: 'Nazwa biletu',
            price: '80',
            description: 'opis biletu'
        }, {
            id: '5',
            name: 'Nazwa biletu',
            price: '120',
            description: 'opis biletu'
        }]
    }
};

export const searchAllArenas = (): Promise<IArena[]> => ((dispatch: Dispatch, getState: () => IState) => {
    /* const token = getState().security.token;
    return fetch('http://d2kqdrigyj90hu.cloudfront.net/api/arena', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token as string
        }
    }).then((res: any) => res.json().content).then((response: IArena[]) => {
        return response;
    }); */
    return new Promise((resolve) => {

        resolve([{
            ...mockArena, address: {
                countryISO: 'PL',
                state: 'Małopolska',
                city: 'Test',
                zipCode: '30-889',
                street: 'Stefana Stolorza',
                number: '1111',
                phone: '666 666 666',
                id: '156'
            }
        }, { ...mockArena, name: 'jakaś 1', id: '2' }, { ...mockArena, id: '3', name: 'mateusz' }, { ...mockArena, id: '4', name: 'przemo' }]);
    });
}) as any;

export const getSingleArena = (arenaId: string): Promise<IArena> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    /* return fetch(`http://d2kqdrigyj90hu.cloudfront.net/api/arena/${arenaId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token as string
        }
    }).then((res: any) => res.json().content).then((response: IArena) => {
        return mockArena;
    }); */
    return new Promise((resolve) => {

        resolve(mockArena);
    });
}) as any;

export const saveNewArena = (arena: IArena): Promise<IArena> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`http://d2kqdrigyj90hu.cloudfront.net/api/arena`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token as string
        },
        body: JSON.stringify(arena)
    }).then((res: any) => res.json().content).then((response: IArena) => {
        return response;
    });
}) as any;

export const updateArena = (arenaId: string, arena: IArena): Promise<IArena> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`http://d2kqdrigyj90hu.cloudfront.net/api/arena/${arenaId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token as string
        },
        body: JSON.stringify(arena)
    }).then((res: any) => res.json().content).then((response: IArena) => {
        return response;
    });
}) as any;