import { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../style/colorStack';

const Wrapper = styled.header`
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    h1 {
        font-size: 3rem;
        font-weight: 700;
        color: ${colorStack.darkGrey};
        margin-bottom: 0.5rem;
    }
    h2 {
        font-size: 1.2rem;
        color: ${colorStack.grey};
    }
`;

interface IProps {
    h1: string;
    h2: string;
}

export const PageTitle: FC<IProps> = props => {
    return (
        <Wrapper>
            <h1>{props.h1}</h1>
            <h2>{props.h2}</h2>
        </Wrapper>
    );
};