import { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../style/colorStack';

const Wrapper = styled.div`
    border-radius: 8px;
    border: 1px solid ${colorStack.darkGrey2};
    margin-bottom: 1rem;
    padding: 1rem;
`;

interface IProps {
    children: React.ReactNode;
}

export const SimpleBox: FC<IProps> = props => {
    return (
        <Wrapper>{props.children}</Wrapper>
    );
};