import { combineReducers } from 'redux';

import security from './securityRedecur';
import profile from './profileReducer';

const store = combineReducers({
    security,
    profile
});

export type IState = ReturnType<typeof store>;

export default store;