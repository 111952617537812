import { FC } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { SimpleBox } from '../common/SimpleBox';
import { NoData } from '../common/NoData';
import { Button } from '../common/Buttons/Button';

const Wrapper = styled.div``;

export const MyEvents: FC = () => {
    const history = useNavigate();

    const addNewEvent = () => {
        history('/edit-event');
    };

    return (
        <Wrapper>
            <SimpleBox>
                <NoData>Nie dołączyłeś jeszcze do żadnego eventu. Możesz to zrobić na stronie Eventy lub z pozycji Areny.</NoData>
                <Button onClick={addNewEvent}>Dodaj nowy event</Button>
            </SimpleBox>
        </Wrapper>
    );
};