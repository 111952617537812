import { FC, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-regular-svg-icons';
import { faBars, faBell, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import useDropdown from 'react-dropdown-hook';
import { useDispatch, useSelector } from 'react-redux';

import { colorStack } from '../../style/colorStack';
import { logout } from '../../actions/securityActions';
import { setCookie } from '../../tools/cookies';
import { IState } from '../../reducers';
import { IUserProfileReducer } from '../../reducers/profileReducer';
import { ContextMenu } from './ContextMenu';

const Wrapper = styled.div`
    background: ${colorStack.darkGrey};
    height: 64px;
    width: 100%;
    > div {
        width: 100%;
        max-width: 1024px;
        margin: 0 auto;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
            width: 64px;
        }
        > a {
            display: flex;
            align-items: center;
            color: ${colorStack.white};
            font-size: 1.5rem;
        }
    }
`;

const UserBox = styled.div`
    position: relative;
    display: flex;
    margin-left: 1rem;
    align-items: center;
    > svg, > img {
        font-size: 2rem;
        color: ${colorStack.blue};
        cursor: pointer;
        width: 32px;
        margin-right: .5rem;
    }
    span {
        cursor: pointer;
        color: ${colorStack.white};
    }
    > div {
        cursor: pointer;
        position: relative;
        align-items: center;
        display: flex;
        > svg {
            font-size: 1rem;
            margin-left: .5rem;
            color: ${colorStack.white};
        }
    }
`;

const CustomLinks = styled(Link)`
    font-size: 1.2rem;
    color: ${colorStack.lightBrown};
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    margin-right: 1rem;
    &:hover {
        transition: color .3s;
        color: ${colorStack.green};
    }
`;

const Right = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const Mobile = styled.div`
    padding: 0 1rem;
    > svg {
        color: ${colorStack.white};
        cursor: pointer;
        font-size: 2rem;
    }
`;

const MobileMenu = styled.div`
    position: fixed;
    top: 64px;
    right: 0;
    height: 100vh;
    background: ${colorStack.darkGrey};
    width: 50%;
    display: flex;
    padding: 1rem;
    z-index: 9;
    flex-direction: column;
    a {
        margin-bottom: 2rem;
        font-size: 1.345rem;
    }
`;

const NotificationBox = styled.div`
    margin-left: 3rem;
`;

const Bell = styled.div`
    position: relative;
    svg {
        color: ${colorStack.lightBrown};
        font-size: 1.5rem;
    }
    > span {
        position: absolute;
        top: -8px;
        right: -8px;
        background: ${colorStack.red};
        color: ${colorStack.white};
        border-radius: 100%;
        padding: 0.3rem;
        font-size: 0.8rem;
    }
`;

export const TopMenu: FC = () => {
    let location = useLocation();
    const history = useNavigate();
    const dispatch = useDispatch();
    const { userProfile } = useSelector<IState, IUserProfileReducer>(state => state.profile);
    const [wrapperMobileRef, dropdownMobileOpen, toggleMobileDropdown, closeMobileDropdown] = useDropdown();
    const [wrapperRef, dropdownOpen, toggleDropdown, closeDropdown] = useDropdown();

    useEffect(() => {
        closeMobileDropdown();
    }, [location]);

    const logoutHandler = () => {
        setCookie('PlayAirAuth', '', 0);
        dispatch<any>(logout());
        history('/');
    };

    const userMenuToggle = () => {
        toggleDropdown();
    };

    return (
        <Wrapper>
            {window.innerWidth >= 1024 ? (
                <div>
                    <Link to="/">
                        <img src={`${process.env.PUBLIC_URL}/img/logo_white.png`} alt="PlayAir logo" />
                        <span>Playair.pro</span>
                    </Link>
                    <Right>
                        <CustomLinks to="/about">o nas</CustomLinks>
                        <CustomLinks to="/events">eventy</CustomLinks>
                        <CustomLinks to="/arenas">areny</CustomLinks>
                        <CustomLinks to="/groups">zespoły</CustomLinks>
                        <CustomLinks to="/services">serwisy</CustomLinks>
                        <CustomLinks to="/bazarek">bazarek</CustomLinks>
                        {!userProfile &&
                            <>
                                <CustomLinks to="/login">logowanie</CustomLinks>
                                <CustomLinks to="/register">rejestracja</CustomLinks>
                            </>
                        }
                        {userProfile &&
                            <>
                                <NotificationBox>
                                    <Bell>
                                        <FontAwesomeIcon icon={faBell} />
                                        <span>9+</span>
                                    </Bell>
                                </NotificationBox>
                                <UserBox ref={wrapperRef} onClick={userMenuToggle}>
                                    {userProfile?.pictureUrl ? (
                                        <img src={userProfile?.pictureUrl} alt="Profil użytkownika" />
                                    ) : (
                                        <FontAwesomeIcon icon={faCircleUser} />
                                    )}
                                    <div>
                                        <span>{userProfile?.nickName || 'Polej'}</span>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </div>
                                    {dropdownOpen &&
                                        <ContextMenu
                                            links={[
                                                {
                                                    label: 'profil',
                                                    action: () => history('/profile/me')
                                                },
                                                {
                                                    label: 'moje eventy',
                                                    action: () => history('/profile/me/events')
                                                },
                                                {
                                                    label: 'moje areny',
                                                    action: () => history('/profile/me/arenas')
                                                },
                                                {
                                                    label: 'moje grupy',
                                                    action: () => history('/profile/me/groups')
                                                },
                                                {
                                                    label: 'moje serwisy',
                                                    action: () => history('/profile/me/services')
                                                },
                                                {
                                                    label: 'znajomi',
                                                    action: () => history('/profile/me/friends')
                                                },
                                                {
                                                    label: 'statystyki',
                                                    action: () => history('/profile/me/stats')
                                                },
                                                {
                                                    label: 'wyloguj',
                                                    action: logoutHandler
                                                }
                                            ]}
                                        />
                                    }
                                </UserBox>
                            </>
                        }
                    </Right>
                </div>
            ) : (
                <Mobile ref={wrapperMobileRef}>
                    <Link to="/">
                        <img src={`${process.env.PUBLIC_URL}/img/logo_white.png`} alt="PlayAir logo" />
                    </Link>
                    <FontAwesomeIcon icon={faBars} onClick={toggleMobileDropdown} />
                    {dropdownMobileOpen &&
                        <MobileMenu>
                            <CustomLinks to="/">home</CustomLinks>
                            <CustomLinks to="/about">o nas</CustomLinks>
                            <CustomLinks to="/events">eventy</CustomLinks>
                            <CustomLinks to="/arenas">areny</CustomLinks>
                            <CustomLinks to="/groups">zespoły</CustomLinks>
                            <CustomLinks to="/services">serwisy</CustomLinks>
                            <CustomLinks to="/bazarek">bazarek</CustomLinks>
                            <CustomLinks to="/profile/me">profil</CustomLinks>
                            <CustomLinks to="/profile/events">moje eventy</CustomLinks>
                            <CustomLinks to="/profile/arenas">moje areny</CustomLinks>
                            <CustomLinks to="/profile/groups">moje grupy</CustomLinks>
                            <CustomLinks to="/profile/friends">znajomi</CustomLinks>
                            <CustomLinks to="/profile/stats">statystyki</CustomLinks>
                            {!userProfile &&
                                <>
                                    <CustomLinks to="/register">rejestracja</CustomLinks>
                                    <CustomLinks to="/login">logowanie</CustomLinks>
                                </>
                            }
                            {userProfile &&
                                <CustomLinks as="button" onClick={logoutHandler} type="button">wyloguj</CustomLinks>
                            }
                        </MobileMenu>
                    }
                </Mobile>
            )}
        </Wrapper>
    );
};