import { faLocationDot, faPhone, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { searchAllArenas } from '../../actions/arenasAction';
import { IArena } from '../../entities/IArena';
import { colorStack } from '../../style/colorStack';
import { ActionMenu } from '../common/ActionMenu';
import { TextComponent } from '../common/Inputs/TextComponent';
import { MultilineTextWithEllipsis } from '../common/MultilineTextWithEllipsis';
import { PageTitle } from '../common/PageTitle';
import { media } from '../../style/breakpoints';

const Wrapper = styled.div`
    position: relative;
`;

const SearchBox = styled.div`
    display: flex;
    justify-content: space-between;
    > div {
        width: 48%;
    }
`;

const SingleArena = styled(Link)`
    border: 1px solid ${colorStack.darkGrey2};
    border-radius: 8px;
    width: 100%;
    padding: 1rem;
    margin-bottom: 2rem;
    background: ${colorStack.white};
    box-shadow: 0px 4px 12px rgba(0,0,0, .16);
    transition: all .3s;
    cursor: pointer;
    &:hover {
        background: ${colorStack.darkGrey2};
    }
    ${media.desktop`
        width: 30%;
    `}
`;

const Top = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    svg, img {
        width: 32px;
        font-size: 1.9rem;
        margin-right: 1rem;
        color: ${colorStack.darkGrey};
    }
    span {
        color: ${colorStack.darkGrey};
    }
`;

const Address = styled.div`
    color: ${colorStack.darkGrey};
    margin-bottom: .5rem;
    svg {
        margin-right: .5rem;
    }
`;

const ArenasList = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`;

export const AllArenas: FC = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const [arenas, setArenas] = useState<IArena[] | undefined>(undefined);
    const [nameSearch, setNameSearch] = useState<string>('');
    const [citySearch, setCitySearch] = useState<string>('');

    useEffect(() => {
        dispatch<any>(searchAllArenas()).then((res: IArena[]) => setArenas(res));
    }, []);

    const changeNameSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setNameSearch(text);
    };

    const changeCitySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setCitySearch(text);
    };

    const addNewArena = () => {
        history('/edit-arena');
    };

    return (
        <Wrapper>
            <ActionMenu>
                <FontAwesomeIcon icon={faPlus} onClick={addNewArena} />
            </ActionMenu>
            <PageTitle h1="Areny" h2="Lista wszystkich aren" />
            <SearchBox>
                <TextComponent value={nameSearch} onChange={changeNameSearch} placeholder="Szukaj po nazwie" />
                <TextComponent value={citySearch} onChange={changeCitySearch} placeholder="Szukaj po mieście" />
            </SearchBox>
            <ArenasList>
                {arenas?.filter(elem => elem.name.toLowerCase().includes(nameSearch.toLowerCase())).filter(elem => elem.address?.city.toLowerCase().includes(citySearch.toLowerCase())).map(arena => (
                    <SingleArena to={`/arena/${arena.id}`}>
                        <Top>
                            <img src={arena.pictureUrl} alt="Logo areny" />
                            <MultilineTextWithEllipsis numberOfLines={1}>
                                <span>{arena.name}</span>
                            </MultilineTextWithEllipsis>
                        </Top>
                        <Address>
                            <MultilineTextWithEllipsis numberOfLines={1}>
                                <FontAwesomeIcon icon={faLocationDot} />{arena.address?.city}, {arena.address?.street} {arena.address?.number}
                            </MultilineTextWithEllipsis>
                        </Address>
                        <Address>
                            <MultilineTextWithEllipsis numberOfLines={1}>
                                <FontAwesomeIcon icon={faPhone} />{arena.address?.phone}
                            </MultilineTextWithEllipsis>
                        </Address>
                    </SingleArena>
                ))}
            </ArenasList>
        </Wrapper>
    );
};