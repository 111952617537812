import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../style/colorStack';

const Wrapper = styled.div`
    border-radius: 100%;
    padding: .5rem;
    position: absolute;
    top: -14px;
    right: -14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 28px;
    height: 28px;
    background: ${colorStack.darkGrey};
    svg {
        color: ${colorStack.white};
        transition: color .3s;
        &:hover {
            color: ${colorStack.blue};
        }
    }
`;

interface IProps {
    onClick(): void;
}

export const CloseButton: FC<IProps> = props => {
    return (
        <Wrapper onClick={props.onClick}>
            <FontAwesomeIcon icon={faXmark} />
        </Wrapper>
    );
};