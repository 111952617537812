import { FC, useState } from 'react';
import styled from 'styled-components';

import { Button } from '../common/Buttons/Button';
import { Checkbox } from '../common/Inputs/Checkbox';
import { TextAreaComponent } from '../common/Inputs/TextAreaComponent';
import { TextComponent } from '../common/Inputs/TextComponent';
import { media } from '../../style/breakpoints';
import { PageTitle } from '../common/PageTitle';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    svg {
        margin-bottom: 4rem;
    }
    p {
        margin: 1rem 0 4rem 0;
        text-align: center;
    }
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${media.desktop`
        flex-direction: row;
    `};
    > div {
        display: flex;
        flex-direction: column;
        ${media.desktop`
            width: 48%;
        `};
    }
`;

export const Contact: FC = () => {
    const [email, setEmail] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [check, setCheck] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');

    const sendForm = () => {
        //
    };

    const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setEmail(text);
    };

    const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setName(text);
    };

    const changeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const text = e.target.value;
        setMessage(text);
    };

    const changeCheckbox = () => {
        setCheck(state => !state);
    };

    return (
        <Wrapper>
            <PageTitle h1="Kontakt" h2="Wyślij nam maila" />
            <Form>
                <div>
                    <TextComponent label="Email" value={email} required onChange={changeEmail}/>
                    <TextComponent label="Imię" value={name} onChange={changeName}/>
                </div>
                <div>
                    <TextAreaComponent label="Wiadomość" required value={message}onChange={changeMessage} />
                </div>
            </Form>
            <Checkbox field="contact" checked={check} onChange={changeCheckbox} label="Korzystając z formularza zgadzasz się na przechowywanie i przetwarzanie twoich danych przez tę witrynę." />
            <Button onClick={sendForm}>Wyślij</Button>
        </Wrapper>
    );
};