import { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colorStack } from '../../style/colorStack';

const Wrapper = styled.footer`
    width: 100%;
    background: ${colorStack.darkGrey2};
`;

const Inner = styled.div`
    padding: 1rem;
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
    > div {
        text-align: center;
        margin: 1rem 0;
    }
    a {
        color: ${colorStack.darkGrey};
        margin-right: 1rem;
    }
`;

export const Footer: FC = () => {
    return (
        <Wrapper>
            <Inner>
                <Link to="/cookies">Polityka Cookies</Link>
                <Link to="/privacy">Polityka Prywatności</Link>
                <div>
                    Copyright 2023 &copy; playair.pro
                </div>
            </Inner>
        </Wrapper>
    );
};