import { Dispatch } from 'redux';

import { ISecurityData } from '../entities/IGlobal';
import { LOGIN_DATA, LOGOUT, SET_TOKEN } from './types/securityTypes';

export const registerUser = (email: string, password: string): Promise<ISecurityData> => ((dispatch: Dispatch) => {
    return fetch('http://d2kqdrigyj90hu.cloudfront.net/api/security/register', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: email,
            password: password
        })
    }).then((res: any) => res.json()).then((response: ISecurityData) => {
        dispatch({
            type: LOGIN_DATA,
            data: {
                token: response.token,
                userId: response.userId,
                email: response.email,
                authorities: response.authorities

            }
        });
        return response;
    });
}) as any;

export const loginUser = (email: string, password: string): Promise<ISecurityData> => ((dispatch: Dispatch) => {
    return fetch('http://d2kqdrigyj90hu.cloudfront.net/api/security/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email,
            password
        })
    }).then((res: any) => res.json()).then((response: ISecurityData) => {
        dispatch({
            type: LOGIN_DATA,
            data: {
                token: response.token,
                userId: response.userId,
                email: response.email,
                authorities: response.authorities

            }
        });
        return response;
    });
}) as any;

export const logout = () => (dispatch: Dispatch) => {
    dispatch({ type: LOGOUT });
};

export const setToken = (token: string) => (dispatch: Dispatch) => {
    dispatch({ type: SET_TOKEN, token: token });
};