import { FC, useState, useRef } from 'react';
import ReactCrop, { Crop, centerCrop, makeAspectCrop } from 'react-image-crop';
import styled from 'styled-components';
import 'react-image-crop/dist/ReactCrop.css';

import { Label } from './Inputs/Label';

const Wrapper = styled.div`
    width: 100%;
    margin-bottom: 1rem;
`;

interface IProps {
    value?: string;
    label?: string;
    required?: boolean;
}

function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: 'px',
                width: 960,
                height: 540
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
}

export const ImageCropper: FC<IProps> = props => {
    const [crop, setCrop] = useState<Crop>();
    const [imgSrc, setImgSrc] = useState<string>('');
    // tslint:disable-next-line:no-null-keyword
    const imgRef = useRef<HTMLImageElement>(null);
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [aspect, setAspect] = useState<number | undefined>(16 / 9);

    const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined);
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || '')
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    };

    return (
        <Wrapper>
            {props.label && <Label required={props.required} label={props.label} />}
            <input type="file" accept="image/*" onChange={onSelectFile} />
            {imgRef && imgSrc &&
                <ReactCrop
                    crop={crop}
                    locked
                    onChange={c => setCrop(c)}
                >
                    {imgRef &&
                        <img
                            ref={imgRef}
                            alt="Crop me"
                            src={imgSrc}
                            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                            onLoad={onImageLoad}
                        />
                    }
                </ReactCrop>
            }
        </Wrapper>
    );
};