import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../style/colorStack';

const Wrapper = styled.button`
    border-radius: 4px;
    height: 30px;
    padding: 0 .8rem;
    display: flex;
    align-items: center;
    background: ${colorStack.green};
    color: ${colorStack.white};
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: .9rem;
    transition: all .3s;
    ${props => props.disabled && css`
        background: ${colorStack.darkGrey2};
        &:hover {
            background: ${colorStack.darkGrey2};
        }
    `};
    &:hover {
        background: ${colorStack.blue};
    }
    svg {
        margin-left: .5rem;
    }
`;

interface IProps {
    children: React.ReactNode | string;
    disabled?: boolean;
    icon?: IconDefinition;
    onClick(): void;
}

export const Button: FC<IProps> = props => {
    return (
        <div>
            <Wrapper type="button" onClick={props.onClick} disabled={props.disabled}>
                {props.children}
                {props.icon && <FontAwesomeIcon icon={props.icon} />}
            </Wrapper>
        </div>
    );
};