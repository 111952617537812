import { FC } from 'react';
import styled from 'styled-components';

import { media } from '../../style/breakpoints';
import { colorStack } from '../../style/colorStack';

const Wrapper = styled.div`
    top: 0;
    right: -50px;
    border-radius: 8px;
    padding: .5rem;
    border: 1px solid ${colorStack.darkGrey2};
    flex-direction: row;
    align-items: center;
    display: flex;
    margin-bottom: 1rem;
    ${media.desktop`
        width: 32px;
        flex-direction: column;
        position: absolute;
    `}
    svg, a {
        width: 100%;
        cursor: pointer;
        margin-right: 1rem;
        border-right: 1px solid ${colorStack.darkGrey2};
        padding-right: 1rem;
        font-size: 1.25rem;
        ${media.desktop`
            font-size: 1rem;
            padding-right: 0;
            margin-bottom: .5rem;
            margin-right: 0;
            padding-bottom: .5rem;
            border-right: none;
            border-bottom: 1px solid ${colorStack.darkGrey2};
            margin-right: 0;
        `}
        &:last-child {
            margin-bottom: 0;
            padding: 0;
            border: none;
        }
        &:hover {
            color: ${colorStack.blue};
            fill: ${colorStack.blue};
        }
    }
`;

interface IPros {
    children: React.ReactNode;
}

export const ActionMenu: FC<IPros> = props => {
    return (
        <Wrapper>
            {props.children}
        </Wrapper>
    );
};