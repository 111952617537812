import { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import Geocode from 'react-geocode';

import { IAddress } from '../../../entities/IGlobal';
import { Label } from './Label';
import { Button } from '../Buttons/Button';
import { Popup } from '../Popup/Popup';
import { Row } from '../Row';
import { TextComponent } from './TextComponent';
import { Checkbox } from './Checkbox';

const Wrapper = styled.div`
    margin-bottom: 1rem;
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    span {
        margin-right: 1rem;
    }
`;

Geocode.setApiKey('AIzaSyCk3gGfsGCjJq_YGZ-FJ8YWrPOxqsjZgeE');
Geocode.setLanguage('pl');

interface IProps {
    label?: string;
    required?: boolean;
    address: IAddress | undefined;
    onSelect(address: IAddress): void;
}

export const AddressComponent: FC<IProps> = props => {
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [address, setAddress] = useState<IAddress>({
        countryISO: '',
        country: '',
        state: '',
        city: '',
        zipCode: '',
        street: '',
        number: '',
        phone: '',
        id: '',
        isArena: false
    });

    useEffect(() => {
        if (props.address) {
            setAddress(props.address);
        }
    }, [props.address, showPopup]);

    const changeHandler = () => {
        setShowPopup(state => !state);
    };

    const saveHandler = () => {
        Geocode.fromAddress(`${address.country}, ${address.zipCode} ${address.city}, ${address.street} ${address.number}`).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                const countryISO = response.results[0].address_components.find((elem: any) => elem.types.includes('country')).short_name;
                setShowPopup(state => !state);
                props.onSelect({...address, lat, lng, countryISO});
            },
            (error) => {
                console.error(error);
            }
        );
    };

    const changeDataHandler = (field: string, value: string) => {
        setAddress(state => ({ ...state, [field]: value }));
    };

    const changeArenaCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(state => ({ ...state, isArena: e.target.checked }));
    };

    return (
        <Wrapper>
            {props.label && <Label required={props.required} label={props.label} />}
            <Content>
                {props.address ? (
                    <>
                        <span>{props.address.zipCode} {props.address.city} {props.address.street} {props.address.number}</span>
                        <Button onClick={changeHandler}>Zmień</Button>
                    </>
                ) : (
                    <Button onClick={changeHandler}>Dodaj adres</Button>
                )}
            </Content>
            <Popup showPopup={showPopup} changeVisibility={changeHandler} title="Adres" saveHandler={saveHandler}>
                <Row numberOfElements={2}>
                    <TextComponent value={address.country} onChange={(e) => changeDataHandler('country', e.target.value)} label="Kraj" required/>
                    <TextComponent value={address.state} onChange={(e) => changeDataHandler('state', e.target.value)} label="Województwo" required/>
                </Row>
                <Row numberOfElements={4}>
                    <TextComponent value={address.zipCode} onChange={(e) => changeDataHandler('zipCode', e.target.value)} label="Kod pocztowy" required/>
                    <TextComponent value={address.city} onChange={(e) => changeDataHandler('city', e.target.value)} label="Miasto" required/>
                    <TextComponent value={address.street} onChange={(e) => changeDataHandler('street', e.target.value)} label="Ulica" required/>
                    <TextComponent value={address.number} onChange={(e) => changeDataHandler('number', e.target.value)} label="Numer" required/>
                </Row>
                <Row numberOfElements={3}>
                    <TextComponent value={address.phone} onChange={(e) => changeDataHandler('phone', e.target.value)} label="Telefon" />
                </Row>
                <Checkbox label="Czy to arena" field="isArena" onChange={changeArenaCheckBox} checked={address.isArena || false} />
            </Popup>
        </Wrapper>
    );
};